import classNames from "classnames";
import { Ref, useMemo } from "react";

import * as d from "@/domain/domain";
import {
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    MessageCallContext,
    TitleChangeMessage,
} from "@/domain/messages";
import { selectUser } from "@/features/users";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";

export interface TitleChangeMessageViewProps {
    msg: TitleChangeMessage;
    messageContentRef?: Ref<HTMLDivElement>;
    currentCallId?: d.CallId;
}

export const TitleChangeMessageView = (
    props: TitleChangeMessageViewProps,
): React.JSX.Element => {
    const { msg, messageContentRef, currentCallId } = props;
    const { oldTitle, newTitle, editorId } = msg;

    const inViewRef = useInViewInterest({
        userIds: editorId,
        callIds: currentCallId,
    });
    const ref = useMergedRefs(messageContentRef, inViewRef);

    const ts = useMemo(() => getMsgTs(msg), [msg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const editor = useSelectorArgs(selectUser, editorId);
    const textContent = (() => {
        if (!oldTitle) {
            return `set the title to "${newTitle}"`;
        }

        return `changed the title from "${oldTitle}" to "${newTitle}"`;
    })();

    // If nothing has changed, don't show the message.
    if (oldTitle === newTitle) {
        return <div ref={messageContentRef}></div>;
    }

    if (!editor) {
        <div className="c-message c-message--unknown" ref={messageContentRef}>
            {`Unknown user ${editorId}`}
        </div>;
    }

    const internalProps: TitleChangeMessageViewInternalProps = {
        editorId,
        editorName: editor?.name,
        ts,
        content: textContent,
        callStatus,
        messageContentRef: ref,
    };
    return <TitleChangeMessageViewInternal {...internalProps} />;
};

interface TitleChangeMessageViewInternalProps {
    editorId?: d.UserId;
    editorName?: string;
    ts?: Date;
    content: string;
    callStatus: MessageCallContext;
    messageContentRef?: Ref<HTMLDivElement>;
}

const TitleChangeMessageViewInternal = (
    props: TitleChangeMessageViewInternalProps,
): React.JSX.Element => {
    const { editorId, editorName, ts, content, messageContentRef, callStatus } = props;

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });
    const actionClassNames = classNames("c-message__action", {
        "c-message__action--live": isLive,
        "c-message__action--ended": isEnded,
    });

    return (
        <div className={containerClassNames}>
            {!!editorId && (
                <Avatar
                    id={editorId}
                    showPresence={false}
                    hidden={!editorName}
                    size="message"
                />
            )}
            <div className="c-message__content">
                {(editorName || ts) && (
                    <div className="c-message__meta">
                        {editorName &&
                            (
                                <span className="c-message__author">
                                    {editorName}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div ref={messageContentRef} className={actionClassNames}>
                    {content}
                </div>
            </div>
        </div>
    );
};
