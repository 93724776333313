import { OidcConfig } from "@/auth/types";
import { getEnvironmentConfig } from "./environment";
import log from "./log";

export async function getOidcConfig(relativeRedirectUri: string | undefined) {
    const env = getEnvironmentConfig();

    const client_id = "user@bondauth";

    // These two could be picked up by OIDC auto-discovery if we were to read the URL
    // from the `${env.oidcAuthority}/.well-known/openid-configuration` endpoint. But
    // it is fixed, and we save ourselves a network request by hardcoding it.
    const token_endpoint = env.oidcAuthority + "/oauth/token";
    const authorization_endpoint = env.oidcAuthority + "/auth";

    // This redirect URI isn't really used for redirecting. But it may contain an e.g.
    // invite code, which the auth backend needs to know about.
    const redirect_uri = new URL(relativeRedirectUri || "/bond", env.redirectUri).toString();

    log.info(
        `OIDC discovery: client_id=${client_id}, token_endpoint=${token_endpoint}, authorization_endpoint=${authorization_endpoint}, redirect_uri=${redirect_uri}`,
    );

    const oidcConfig: OidcConfig = {
        client_id,
        authorization_endpoint,
        token_endpoint,
        redirect_uri,
        token_scope: "openid profile email offline_access",
    };
    return oidcConfig;
}
