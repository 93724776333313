import { useCallback, useEffect, useRef, useState } from "react";

import {
    checkMediaDeviceExists,
    DeviceKind_Type,
    MediaKind,
    MediaKind_Type,
    MediaPublishFunc,
    toDeviceKind,
} from "@/domain/mediaDevices";
import {
    DevicePermissions,
    selectCurrentDevice,
    selectDevicePermissions,
    setPreferredDevice,
} from "@/features/mediaDevices";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch } from "@/store/redux";

const defaultAudioConstraints = (deviceId?: string) => ({
    deviceId: deviceId ? { exact: deviceId } : undefined,
    sampleRate: { ideal: 32000 },
    autoGainControl: { ideal: true },
    noiseSuppression: { ideal: true },
    echoCancellation: { ideal: true },
});

const defaultVideoConstraints = (deviceId?: string) => ({
    deviceId: deviceId ? { exact: deviceId } : undefined,

    width: { ideal: 1280 },
    height: { ideal: 720 },
    aspectRatio: { ideal: 16 / 9 },
});

type GetMediaConstraintsFn = (
    kind: DeviceKind_Type,
    deviceId?: string,
) => Promise<Optional<MediaStreamConstraints>>;

function useMediaConstraints(): GetMediaConstraintsFn {
    // Not supported by all browsers.
    const tryVoiceIsolation = useBooleanFeatureFlag("media-devices-voice-isolation");
    const voiceIsolation = tryVoiceIsolation &&
        ("voiceIsolation" in navigator.mediaDevices.getSupportedConstraints());

    // Get media device constraints for the exact device if it exists,
    // else use the default constraints.
    return useCallback(async (
        kind: DeviceKind_Type,
        deviceId?: string,
    ) => {
        const deviceExists = await checkMediaDeviceExists(kind, deviceId);
        const desiredDeviceId = deviceExists ? deviceId : undefined;

        switch (kind) {
            case MediaKind.AudioInput:
                return {
                    audio: {
                        ...defaultAudioConstraints(desiredDeviceId),
                        voiceIsolation,
                    },
                };
            case MediaKind.VideoInput:
                return { video: defaultVideoConstraints(desiredDeviceId) };
        }
    }, [voiceIsolation]);
}

export interface useMediaDeviceProps {
    // Whether the device is currently enabled.
    // (e.g. false when we are muting the microphone)
    enabled: boolean;
    // The kind of device to use
    kind: MediaKind_Type;
    // Whether to use the default device constraints if no device id is selected
    fallbackConstraints?: boolean;
    // The function to call upon getting user media
    onGetMedia: MediaPublishFunc;
    // The function to call upon cleaning up
    onCleanup?: () => void;
    // The function to call when media is ended externally (e.g. permissions revoked)
    onEndedExternally?: () => void;
    // The function to call upon no device id being selected.
    // This is currently only used to play a fake sound in the `AudioPreview`.
    onNoDevice?: () => void;
}

export function useMediaDevice(props: useMediaDeviceProps) {
    const {
        enabled,
        fallbackConstraints,
        kind,
        onCleanup,
        onEndedExternally,
        onGetMedia,
        onNoDevice,
    } = props;

    const dispatch = useAppDispatch();
    const deviceKind = toDeviceKind(kind);

    const currentDeviceId = useSelectorArgs(selectCurrentDevice, deviceKind);
    const permissions = useSelectorArgs(selectDevicePermissions, deviceKind);

    // Guard when we set the device ID and cause re-fetching media.
    // When we unmute with no selection, we update the preferred device ID to match the MediaStream.
    // Now when we open the selector, we enumerate devices and choose the preferred device ID.
    // Without this guard, we would re-fetch media as the current device ID changes.
    // => Don't re-fetch media if we select the same device we just unmuted with.
    const [deviceId, setDeviceId] = useState<Optional<string>>();
    const previousDeviceId = useRef<Optional<string>>();
    const changedDeviceId = previousDeviceId.current !== currentDeviceId;
    useEffect(() => {
        if (changedDeviceId) {
            setDeviceId(currentDeviceId);
        }
    }, [changedDeviceId, currentDeviceId]);

    // A callback used after fetching media to update the preferred device if no
    // device is selected already, and update the previously fetched device ID
    // for the next media fetch.
    // We return a function here so we can nicely chain this in the Promise.then
    const updatePreferredDevice = useCallback((
        kind: DeviceKind_Type,
        deviceId?: string,
    ) =>
    (s: MediaStream) => {
        if (deviceId) {
            previousDeviceId.current = deviceId;
            return s;
        }

        const tracks = s.getTracks();
        if (!tracks.length) {
            previousDeviceId.current = undefined;
            return s;
        }

        // Inspect the media stream tracks to get its Device ID
        const newDeviceId = tracks[0].getSettings().deviceId;
        if (newDeviceId) {
            dispatch(setPreferredDevice(kind, newDeviceId));
            previousDeviceId.current = newDeviceId;
        }
        else {
            previousDeviceId.current = undefined;
        }

        return s;
    }, [dispatch]);

    // A bool indicating whether permissions are denied.
    // This will stop us from calling getMedia whenever permissions change (e.g.
    // they flash as Pending while we enumerate devices in the device dropdown)
    const permissionsDenied = permissions === DevicePermissions.Denied;

    const streamRef = useRef<Optional<MediaStream>>();
    const stopStream = () => {
        streamRef.current?.getTracks().forEach(t => {
            t.stop();
        });
    };

    const getConstraints = useMediaConstraints();

    // A callback to get media depending on device kind/constrains
    const getMedia = useCallback(async (): Promise<Optional<MediaStream>> => {
        if (kind === MediaKind.Display) {
            log.info("Fetching display media...");
            return navigator.mediaDevices?.getDisplayMedia();
        }

        // Check whether we should get media for input device
        const shouldGetMedia = deviceId || fallbackConstraints || onNoDevice;
        if (!shouldGetMedia || permissionsDenied) {
            return;
        }

        // Get media, by requesting from user, or by calling fallback function
        if (deviceId || fallbackConstraints) {
            const constraints = await getConstraints(kind, deviceId);
            if (!constraints) {
                log.warn("Unsupported device kind");
                return;
            }

            log.info(`Fetching user media for ${kind} with constraints`, constraints);
            return navigator.mediaDevices?.getUserMedia(constraints)
                // Update preferred device if no device selected already
                .then(updatePreferredDevice(kind, deviceId));
        }
        else {
            onNoDevice?.();
        }
    }, [
        deviceId,
        kind,
        getConstraints,
        onNoDevice,
        permissionsDenied,
        updatePreferredDevice,
        fallbackConstraints,
    ]);

    // Handle getting media and cleaning up streams
    useEffect(() => {
        if (!enabled) {
            return;
        }

        let hasCleanedUp = false;
        getMedia()?.then((s?: MediaStream) => {
            if (hasCleanedUp) {
                // Don't save media stream if this is called after cleanup
                s?.getTracks().forEach(t => t.stop());
                return;
            }
            streamRef.current = s;
            if (s) {
                log.debug("Media fetched successfully:", s.id);

                // Log device info
                s.getTracks().forEach(t => {
                    log.debug(`Media stream has ${t.kind} track: ${t.id}`, t.getSettings());
                });

                // Handle media stream being ended externally before clean up
                s.getTracks().forEach(t => {
                    t.onended = () => {
                        if (!hasCleanedUp) onEndedExternally?.();
                    };
                });

                onGetMedia(s);
            }
        }).catch(e => {
            log.error(`Get user media exception ${kind}: ${e}`);
            stopStream();
            onEndedExternally?.();
        });

        // Cleanup by stopping streams and calling possible cleanup function
        return () => {
            hasCleanedUp = true;
            stopStream();
            onCleanup?.();
        };
    }, [
        enabled,
        getMedia,
        kind,
        onCleanup,
        onEndedExternally,
        onGetMedia,
    ]);
}
