import { DeviceKind_Type } from "@/domain/mediaDevices";
import {
    selectAllDeviceIds,
    selectCurrentDevice,
    selectDeviceByIdAndKind,
    setCurrentDevice,
} from "@/features/mediaDevices";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isNativePlatform } from "@/misc/capacitor";
import { useAppDispatch } from "@/store/redux";

type MediaDeviceSelectorProps = {
    kind: DeviceKind_Type;
};

export default function MediaDeviceSelector(props: MediaDeviceSelectorProps): React.JSX.Element {
    const { kind } = props;
    const dispatch = useAppDispatch();

    // TODO: hide selector when no devices enumerated/permission denied
    const deviceIds = useSelectorArgs(selectAllDeviceIds, kind);
    const selectedDevice = useSelectorArgs(selectCurrentDevice, kind);

    let label: string;
    switch (kind) {
        case "videoinput":
            label = "Camera";
            break;
        case "audioinput":
            if (isNativePlatform()) {
                label = "Audio device";
            }
            else {
                label = "Microphone";
            }
            break;
        case "audiooutput":
            label = "Speaker";
            break;
        default:
            label = "";
    }

    return (
        <div className="c-form-element c-form-element--margin">
            <label className="c-label c-label--preferences">{label}</label>
            <select
                value={selectedDevice}
                className="c-select u-truncate-auto"
                onChange={e => {
                    dispatch(setCurrentDevice(kind, e.target.value));
                }}
                onMouseDown={e => {
                    // Stop mouse event propagating to parent (e.g. Settings
                    // modal outside click detector)
                    e.stopPropagation();
                }}
                data-testid={`device-${kind}-select`}
            >
                {deviceIds.map(id => <DeviceRow key={id} id={id} kind={kind} />)}
            </select>
        </div>
    );
}

export interface DeviceRowProps {
    id: string;
    kind: DeviceKind_Type;
}

function DeviceRow(props: DeviceRowProps): React.JSX.Element {
    const { id, kind } = props;
    const device = useSelectorArgs(selectDeviceByIdAndKind, id, kind);
    return (
        <option value={id} data-testid={id + "-option"}>
            {device?.label || "Unknown device"}
        </option>
    );
}
