import { Capacitor } from "@capacitor/core";
import { To } from "react-router-dom";

import { FrontendPlatformType } from "../../gen/proto/domain/domain_pb";

import { nativeTargets } from "@/misc/environment";
import log from "@/misc/log";
import { getRouter } from "@/misc/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Clipboard } from "@capacitor/clipboard";

export const isNativePlatform = Capacitor.isNativePlatform;
export const isNativeAndroidPlatform = () => Capacitor.getPlatform() === "android";
export const isNativeIosPlatform = () => Capacitor.getPlatform() === "ios";
// isNativeAndroidPlatform || isNativeIosPlatform <=> isNativePlatform

export const getFrontendPlatform = () => {
    let frontendPlatform = FrontendPlatformType.UNSPECIFIED;
    if (!isNativePlatform()) {
        frontendPlatform = FrontendPlatformType.BROWSER;
    }
    else {
        if (isNativeAndroidPlatform()) {
            frontendPlatform = FrontendPlatformType.ANDROID;
        }
        if (isNativeIosPlatform()) {
            frontendPlatform = FrontendPlatformType.IOS;
        }
    }

    return frontendPlatform;
};

export const nativePlatformTargetKey = "nativePlatformTarget";
export const getNativeTarget = () => {
    if (!isNativePlatform()) {
        return undefined;
    }

    return localStorage.getItem(nativePlatformTargetKey) ?? nativeTargets.bondDev;
};

export const capacitorSetup = () => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        log.info("Native client redirecting to: ", event);

        const urlPrefix = "io.avos.beyond.app://app";
        const bondUrlPrefix = urlPrefix + "/bond";
        const inviteUrlPrefix = urlPrefix + "/invite";

        const router = getRouter();
        const routeTo = (route: To) => {
            log.info(`route to: ${route}`);
            router.navigate(route, { replace: true });
        };

        // Example url: io.avos.beyond.app://app/bond

        if (event.url.startsWith(bondUrlPrefix)) {
            const slug = event.url.replace(bondUrlPrefix, "/mobile/tab/mybonds");
            if (slug) {
                routeTo(slug);
            }
        }
        else if (event.url.startsWith(inviteUrlPrefix)) {
            const slug = event.url.replace(inviteUrlPrefix, "/invite");
            if (slug) {
                routeTo(slug);
            }
        }
        else {
            // Default to the MyBonds tab view
            log.error("Unknown native client redirect:", event);
            routeTo("/mobile/tab/mybonds");
        }
    });
};

export const copyToClipboard = async (string: string) => {
    if (isNativePlatform()) {
        await Clipboard.write({ string }).catch(() => {
            throw new Error("Failed to copy string to native clipboard.");
        });
    }
    else {
        await navigator.clipboard.writeText(string).catch(() => {
            throw new Error("Failed to copy string to clipboard.");
        });
    }
};
