// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file bonds/bonds.proto (package bonds, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AudienceOpSet, BondId, BondKnowledgeId, BondKnowledgeType, BondSet, CallId, CallSet, ChannelId, ChannelSet, CrewId, InvolvementRole, OrgId, PersonId, PersonSet, PrivacyLevel, RtcSessionId, SquadId, SquadSet, UserId, UserSet } from "../domain/domain_pb.js";
import { SendChatMessageDetails, SendChatMessageRequest, SendChatMessageResponse, SubUserMaxReadSeqNumsResponse } from "../chats/chats_pb.js";
import { CallKnowledgePreview, CallParameters, RtcSessionEvent, StartCallResponse } from "../calls/calls_pb.js";

/**
 * @generated from message bonds.BondOverview
 */
export const BondOverview = proto3.makeMessageType(
  "bonds.BondOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: BondId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
    { no: 14, name: "knowledge_preview", kind: "message", T: BondKnowledgePreview },
    { no: 6, name: "contributors", kind: "message", T: UserSet },
    { no: 7, name: "followers", kind: "message", T: UserSet },
    { no: 8, name: "external_users", kind: "message", T: UserSet },
    { no: 9, name: "channel_id", kind: "message", T: ChannelId },
    { no: 10, name: "max_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "last_activity_at", kind: "message", T: Timestamp },
    { no: 12, name: "live_calls", kind: "message", T: CallSet },
    { no: 15, name: "crew_id", kind: "message", T: CrewId },
    { no: 16, name: "invited_persons", kind: "message", T: PersonSet },
    { no: 17, name: "invited_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "creator_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.BondHerald
 */
export const BondHerald = proto3.makeMessageType(
  "bonds.BondHerald",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
    { no: 3, name: "last_activity_at", kind: "message", T: Timestamp },
    { no: 4, name: "requester_is_follower", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message bonds.BondKnowledge
 */
export const BondKnowledge = proto3.makeMessageType(
  "bonds.BondKnowledge",
  () => [
    { no: 1, name: "generated", kind: "message", T: Timestamp },
    { no: 11, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(BondKnowledgeType) },
    { no: 12, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "id", kind: "message", T: BondKnowledgeId },
    { no: 10, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.BondKnowledgePreview
 */
export const BondKnowledgePreview = proto3.makeMessageType(
  "bonds.BondKnowledgePreview",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ai_generated_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "detailed_summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.UserCatchupSummary
 */
export const UserCatchupSummary = proto3.makeMessageType(
  "bonds.UserCatchupSummary",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.BondInvitee
 */
export const BondInvitee = proto3.makeMessageType(
  "bonds.BondInvitee",
  () => [
    { no: 3, name: "person_id", kind: "message", T: PersonId, oneof: "via" },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "via" },
  ],
);

/**
 * @generated from message bonds.BondInvite
 */
export const BondInvite = proto3.makeMessageType(
  "bonds.BondInvite",
  () => [
    { no: 8, name: "opaque_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "originator_id", kind: "message", T: UserId },
    { no: 4, name: "expires_at", kind: "message", T: Timestamp, oneof: "expiry" },
    { no: 5, name: "expired", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "expiry" },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "invitee", kind: "message", T: BondInvitee, oneof: "target" },
    { no: 7, name: "is_common", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "target" },
    { no: 10, name: "accepted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "rejected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message bonds.BondTitles
 */
export const BondTitles = proto3.makeMessageType(
  "bonds.BondTitles",
  () => [
    { no: 1, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ai_generated_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.GetBondsRequest
 */
export const GetBondsRequest = proto3.makeMessageType(
  "bonds.GetBondsRequest",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetBondsResponse
 */
export const GetBondsResponse = proto3.makeMessageType(
  "bonds.GetBondsResponse",
  () => [
    { no: 1, name: "bonds", kind: "message", T: BondOverview, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForChannelsRequest
 */
export const GetBondIdsForChannelsRequest = proto3.makeMessageType(
  "bonds.GetBondIdsForChannelsRequest",
  () => [
    { no: 3, name: "channel_ids", kind: "message", T: ChannelId, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForChannelsResponse
 */
export const GetBondIdsForChannelsResponse = proto3.makeMessageType(
  "bonds.GetBondIdsForChannelsResponse",
  () => [
    { no: 3, name: "bond_ids", kind: "message", T: BondId, repeated: true },
    { no: 4, name: "crew_ids", kind: "message", T: CrewId, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForCallsRequest
 */
export const GetBondIdsForCallsRequest = proto3.makeMessageType(
  "bonds.GetBondIdsForCallsRequest",
  () => [
    { no: 1, name: "call_ids", kind: "message", T: CallId, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondIdsForCallsResponse
 */
export const GetBondIdsForCallsResponse = proto3.makeMessageType(
  "bonds.GetBondIdsForCallsResponse",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondId, repeated: true },
    { no: 3, name: "crew_ids", kind: "message", T: CrewId, repeated: true },
  ],
);

/**
 * @generated from message bonds.ListSquadBondsRequest
 */
export const ListSquadBondsRequest = proto3.makeMessageType(
  "bonds.ListSquadBondsRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
  ],
);

/**
 * @generated from message bonds.ListSquadBondsResponse
 */
export const ListSquadBondsResponse = proto3.makeMessageType(
  "bonds.ListSquadBondsResponse",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetCallParentDetailsRequest
 */
export const GetCallParentDetailsRequest = proto3.makeMessageType(
  "bonds.GetCallParentDetailsRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.GetCallParentDetailsResponse
 */
export const GetCallParentDetailsResponse = proto3.makeMessageType(
  "bonds.GetCallParentDetailsResponse",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 3, name: "org_ids", kind: "message", T: OrgId, repeated: true },
    { no: 4, name: "initiator_id", kind: "message", T: UserId },
    { no: 5, name: "channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message bonds.ListUserBondsRequest
 */
export const ListUserBondsRequest = proto3.makeMessageType(
  "bonds.ListUserBondsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
  ],
);

/**
 * @generated from message bonds.ListUserBondsResponse
 */
export const ListUserBondsResponse = proto3.makeMessageType(
  "bonds.ListUserBondsResponse",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.CreateBondRequest
 */
export const CreateBondRequest = proto3.makeMessageType(
  "bonds.CreateBondRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 3, name: "squad_ids", kind: "message", T: SquadId, repeated: true },
    { no: 4, name: "privacy", kind: "enum", T: proto3.getEnumType(PrivacyLevel) },
    { no: 5, name: "user_specified_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "followers", kind: "message", T: UserSet },
    { no: 10, name: "bond_titles", kind: "message", T: BondTitles },
    { no: 11, name: "idempotent_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.CreateBondResponse
 */
export const CreateBondResponse = proto3.makeMessageType(
  "bonds.CreateBondResponse",
  () => [
    { no: 1, name: "new_bond_id", kind: "message", T: BondId },
    { no: 2, name: "new_channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message bonds.CreateBondFromChatMessageRequest
 */
export const CreateBondFromChatMessageRequest = proto3.makeMessageType(
  "bonds.CreateBondFromChatMessageRequest",
  () => [
    { no: 1, name: "bond", kind: "message", T: CreateBondRequest },
    { no: 2, name: "message", kind: "message", T: SendChatMessageDetails },
    { no: 3, name: "audience_ops", kind: "message", T: AudienceOpSet },
  ],
);

/**
 * @generated from message bonds.CreateBondFromChatMessageResponse
 */
export const CreateBondFromChatMessageResponse = proto3.makeMessageType(
  "bonds.CreateBondFromChatMessageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CreateBondResponse },
  ],
);

/**
 * @generated from message bonds.CreateBondFromCallRequest
 */
export const CreateBondFromCallRequest = proto3.makeMessageType(
  "bonds.CreateBondFromCallRequest",
  () => [
    { no: 1, name: "bond", kind: "message", T: CreateBondRequest },
    { no: 2, name: "audience_ops", kind: "message", T: AudienceOpSet },
  ],
);

/**
 * @generated from message bonds.CreateBondFromCallResponse
 */
export const CreateBondFromCallResponse = proto3.makeMessageType(
  "bonds.CreateBondFromCallResponse",
  () => [
    { no: 1, name: "new_bond", kind: "message", T: CreateBondResponse },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeStalenessRequest
 */
export const GetBondKnowledgeStalenessRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgeStalenessRequest",
  () => [
    { no: 1, name: "bond_ids", kind: "message", T: BondId, repeated: true },
    { no: 2, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(BondKnowledgeType) },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeStalenessResponse
 */
export const GetBondKnowledgeStalenessResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgeStalenessResponse",
  () => [
    { no: 1, name: "knowledge_lag", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgePreviewRequest
 */
export const GetBondKnowledgePreviewRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgePreviewRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgePreviewResponse
 */
export const GetBondKnowledgePreviewResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgePreviewResponse",
  () => [
    { no: 1, name: "preview", kind: "message", T: BondKnowledgePreview },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeByTypeRequest
 */
export const GetBondKnowledgeByTypeRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgeByTypeRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(BondKnowledgeType) },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgeByTypeResponse
 */
export const GetBondKnowledgeByTypeResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgeByTypeResponse",
  () => [
    { no: 1, name: "bond_knowledges", kind: "message", T: BondKnowledge, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgesRequest
 */
export const GetBondKnowledgesRequest = proto3.makeMessageType(
  "bonds.GetBondKnowledgesRequest",
  () => [
    { no: 1, name: "bond_set", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.GetBondKnowledgesResponse
 */
export const GetBondKnowledgesResponse = proto3.makeMessageType(
  "bonds.GetBondKnowledgesResponse",
  () => [
    { no: 1, name: "bond_knowledges", kind: "message", T: BondKnowledge, repeated: true },
  ],
);

/**
 * @generated from message bonds.GetChannelIdFromCallRequest
 */
export const GetChannelIdFromCallRequest = proto3.makeMessageType(
  "bonds.GetChannelIdFromCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.GetChannelIdFromCallResponse
 */
export const GetChannelIdFromCallResponse = proto3.makeMessageType(
  "bonds.GetChannelIdFromCallResponse",
  () => [
    { no: 1, name: "channel_id", kind: "message", T: ChannelId },
  ],
);

/**
 * @generated from message bonds.AddFollowersToBondRequest
 */
export const AddFollowersToBondRequest = proto3.makeMessageType(
  "bonds.AddFollowersToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
    { no: 4, name: "invitees_to_resolve", kind: "message", T: PersonSet },
  ],
);

/**
 * @generated from message bonds.AddFollowersToBondResponse
 */
export const AddFollowersToBondResponse = proto3.makeMessageType(
  "bonds.AddFollowersToBondResponse",
  [],
);

/**
 * @generated from message bonds.RemoveFollowersFromBondRequest
 */
export const RemoveFollowersFromBondRequest = proto3.makeMessageType(
  "bonds.RemoveFollowersFromBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.RemoveFollowersFromBondResponse
 */
export const RemoveFollowersFromBondResponse = proto3.makeMessageType(
  "bonds.RemoveFollowersFromBondResponse",
  [],
);

/**
 * @generated from message bonds.AddArchiversToBondRequest
 */
export const AddArchiversToBondRequest = proto3.makeMessageType(
  "bonds.AddArchiversToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.AddArchiversToBondResponse
 */
export const AddArchiversToBondResponse = proto3.makeMessageType(
  "bonds.AddArchiversToBondResponse",
  [],
);

/**
 * @generated from message bonds.RemoveArchiversFromBondRequest
 */
export const RemoveArchiversFromBondRequest = proto3.makeMessageType(
  "bonds.RemoveArchiversFromBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "users", kind: "message", T: UserSet },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.RemoveArchiversFromBondResponse
 */
export const RemoveArchiversFromBondResponse = proto3.makeMessageType(
  "bonds.RemoveArchiversFromBondResponse",
  [],
);

/**
 * @generated from message bonds.AddSquadsToBondRequest
 */
export const AddSquadsToBondRequest = proto3.makeMessageType(
  "bonds.AddSquadsToBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "squad_ids", kind: "message", T: SquadSet },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.AddSquadsToBondResponse
 */
export const AddSquadsToBondResponse = proto3.makeMessageType(
  "bonds.AddSquadsToBondResponse",
  [],
);

/**
 * @generated from message bonds.GetBondRoleHoldersRequest
 */
export const GetBondRoleHoldersRequest = proto3.makeMessageType(
  "bonds.GetBondRoleHoldersRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(InvolvementRole) },
  ],
);

/**
 * @generated from message bonds.GetBondRoleHoldersResponse
 */
export const GetBondRoleHoldersResponse = proto3.makeMessageType(
  "bonds.GetBondRoleHoldersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.GetFollowerLaggardsRequest
 */
export const GetFollowerLaggardsRequest = proto3.makeMessageType(
  "bonds.GetFollowerLaggardsRequest",
  () => [
    { no: 1, name: "primary_channel_id", kind: "message", T: ChannelId },
    { no: 2, name: "sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.GetFollowerLaggardsResponse
 */
export const GetFollowerLaggardsResponse = proto3.makeMessageType(
  "bonds.GetFollowerLaggardsResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.GetUserInSharedOrgForEmailRequest
 */
export const GetUserInSharedOrgForEmailRequest = proto3.makeMessageType(
  "bonds.GetUserInSharedOrgForEmailRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "sender_id", kind: "message", T: UserId },
    { no: 3, name: "target_email_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.GetUserInSharedOrgForEmailResponse
 */
export const GetUserInSharedOrgForEmailResponse = proto3.makeMessageType(
  "bonds.GetUserInSharedOrgForEmailResponse",
  () => [
    { no: 1, name: "target_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SendChatMessageViaBondRequest
 */
export const SendChatMessageViaBondRequest = proto3.makeMessageType(
  "bonds.SendChatMessageViaBondRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: SendChatMessageRequest },
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "audience_ops", kind: "message", T: AudienceOpSet },
  ],
);

/**
 * @generated from message bonds.SendChatMessageViaBondResponse
 */
export const SendChatMessageViaBondResponse = proto3.makeMessageType(
  "bonds.SendChatMessageViaBondResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SendChatMessageResponse },
  ],
);

/**
 * @generated from message bonds.StartCallViaBondRequest
 */
export const StartCallViaBondRequest = proto3.makeMessageType(
  "bonds.StartCallViaBondRequest",
  () => [
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "call_parameters", kind: "message", T: CallParameters },
  ],
);

/**
 * @generated from message bonds.StartCallViaBondResponse
 */
export const StartCallViaBondResponse = proto3.makeMessageType(
  "bonds.StartCallViaBondResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: StartCallResponse },
    { no: 2, name: "did_redirect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondUserSpecifiedTitleRequest
 */
export const UpdateBondUserSpecifiedTitleRequest = proto3.makeMessageType(
  "bonds.UpdateBondUserSpecifiedTitleRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "acting_user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.UpdateBondUserSpecifiedTitleResponse
 */
export const UpdateBondUserSpecifiedTitleResponse = proto3.makeMessageType(
  "bonds.UpdateBondUserSpecifiedTitleResponse",
  [],
);

/**
 * @generated from message bonds.UpdateBondAiGeneratedTitleRequest
 */
export const UpdateBondAiGeneratedTitleRequest = proto3.makeMessageType(
  "bonds.UpdateBondAiGeneratedTitleRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondAiGeneratedTitleResponse
 */
export const UpdateBondAiGeneratedTitleResponse = proto3.makeMessageType(
  "bonds.UpdateBondAiGeneratedTitleResponse",
  [],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeSummaryAllContentRequest
 */
export const UpdateBondKnowledgeSummaryAllContentRequest = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeSummaryAllContentRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "detailed_summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeSummaryAllContentResponse
 */
export const UpdateBondKnowledgeSummaryAllContentResponse = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeSummaryAllContentResponse",
  [],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeImageUrlRequest
 */
export const UpdateBondKnowledgeImageUrlRequest = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeImageUrlRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateBondKnowledgeImageUrlResponse
 */
export const UpdateBondKnowledgeImageUrlResponse = proto3.makeMessageType(
  "bonds.UpdateBondKnowledgeImageUrlResponse",
  [],
);

/**
 * @generated from message bonds.UpdateUserBondKnowledgeRequest
 */
export const UpdateUserBondKnowledgeRequest = proto3.makeMessageType(
  "bonds.UpdateUserBondKnowledgeRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_summarised_seq", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "last_read_seq_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message bonds.UpdateUserBondKnowledgeResponse
 */
export const UpdateUserBondKnowledgeResponse = proto3.makeMessageType(
  "bonds.UpdateUserBondKnowledgeResponse",
  [],
);

/**
 * @generated from message bonds.CreateBondInviteRequest
 */
export const CreateBondInviteRequest = proto3.makeMessageType(
  "bonds.CreateBondInviteRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "originator_id", kind: "message", T: UserId },
    { no: 3, name: "expires_at", kind: "message", T: Timestamp },
    { no: 6, name: "invitee", kind: "message", T: BondInvitee, oneof: "target" },
    { no: 7, name: "is_common", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "target" },
  ],
);

/**
 * @generated from message bonds.CreateBondInviteResponse
 */
export const CreateBondInviteResponse = proto3.makeMessageType(
  "bonds.CreateBondInviteResponse",
  () => [
    { no: 1, name: "opaque_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.FindBondInviteRequest
 */
export const FindBondInviteRequest = proto3.makeMessageType(
  "bonds.FindBondInviteRequest",
  () => [
    { no: 1, name: "opaque_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message bonds.FindBondInviteResponse
 */
export const FindBondInviteResponse = proto3.makeMessageType(
  "bonds.FindBondInviteResponse",
  () => [
    { no: 1, name: "invite", kind: "message", T: BondInvite },
  ],
);

/**
 * @generated from message bonds.ResolveBondInviteRequest
 */
export const ResolveBondInviteRequest = proto3.makeMessageType(
  "bonds.ResolveBondInviteRequest",
  () => [
    { no: 1, name: "invitee_id", kind: "message", T: PersonId },
    { no: 2, name: "bond_id", kind: "message", T: BondId },
    { no: 3, name: "accept", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "resolve_with" },
    { no: 4, name: "reject", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "resolve_with" },
  ],
);

/**
 * @generated from message bonds.ResolveBondInviteResponse
 */
export const ResolveBondInviteResponse = proto3.makeMessageType(
  "bonds.ResolveBondInviteResponse",
  [],
);

/**
 * @generated from message bonds.ListInvitedUsersRequest
 */
export const ListInvitedUsersRequest = proto3.makeMessageType(
  "bonds.ListInvitedUsersRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.ListInvitedUsersResponse
 */
export const ListInvitedUsersResponse = proto3.makeMessageType(
  "bonds.ListInvitedUsersResponse",
  () => [
    { no: 1, name: "invited_persons", kind: "message", T: PersonSet },
    { no: 2, name: "invited_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message bonds.FilterBondsWithoutAiTitleRequest
 */
export const FilterBondsWithoutAiTitleRequest = proto3.makeMessageType(
  "bonds.FilterBondsWithoutAiTitleRequest",
  () => [
    { no: 1, name: "channel_ids", kind: "message", T: ChannelSet },
  ],
);

/**
 * @generated from message bonds.FilterBondsWithoutAiTitleResponse
 */
export const FilterBondsWithoutAiTitleResponse = proto3.makeMessageType(
  "bonds.FilterBondsWithoutAiTitleResponse",
  () => [
    { no: 1, name: "channel_ids", kind: "message", T: ChannelSet },
  ],
);

/**
 * @generated from message bonds.SubBondsRequest
 */
export const SubBondsRequest = proto3.makeMessageType(
  "bonds.SubBondsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubBondsResponse
 */
export const SubBondsResponse = proto3.makeMessageType(
  "bonds.SubBondsResponse",
  () => [
    { no: 1, name: "bond_overview", kind: "message", T: BondOverview, oneof: "bond_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "bond_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondsListRequest
 */
export const SubBondsListRequest = proto3.makeMessageType(
  "bonds.SubBondsListRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubBondsListResponse
 */
export const SubBondsListResponse = proto3.makeMessageType(
  "bonds.SubBondsListResponse",
  () => [
    { no: 1, name: "herald", kind: "message", T: BondHerald, oneof: "herald_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "herald_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondRequest
 */
export const SubBondRequest = proto3.makeMessageType(
  "bonds.SubBondRequest",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
  ],
);

/**
 * @generated from message bonds.SubBondResponse
 */
export const SubBondResponse = proto3.makeMessageType(
  "bonds.SubBondResponse",
  () => [
    { no: 1, name: "bond", kind: "message", T: BondOverview, oneof: "bond_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "bond_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondsV2Request
 */
export const SubBondsV2Request = proto3.makeMessageType(
  "bonds.SubBondsV2Request",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondsV2Response
 */
export const SubBondsV2Response = proto3.makeMessageType(
  "bonds.SubBondsV2Response",
  () => [
    { no: 1, name: "bond", kind: "message", T: BondOverview, oneof: "bond_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "bond_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubMsgSeqNumsRequest
 */
export const SubMsgSeqNumsRequest = proto3.makeMessageType(
  "bonds.SubMsgSeqNumsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubMsgSeqNumsResponse
 */
export const SubMsgSeqNumsResponse = proto3.makeMessageType(
  "bonds.SubMsgSeqNumsResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SubUserMaxReadSeqNumsResponse },
  ],
);

/**
 * @generated from message bonds.SubBondMsgSeqNumsRequest
 */
export const SubBondMsgSeqNumsRequest = proto3.makeMessageType(
  "bonds.SubBondMsgSeqNumsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondMsgSeqNumsResponse
 */
export const SubBondMsgSeqNumsResponse = proto3.makeMessageType(
  "bonds.SubBondMsgSeqNumsResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: SubUserMaxReadSeqNumsResponse },
  ],
);

/**
 * @generated from message bonds.SubArchivedBondsListDiffRequest
 */
export const SubArchivedBondsListDiffRequest = proto3.makeMessageType(
  "bonds.SubArchivedBondsListDiffRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message bonds.SubArchivedBondsListDiffResponse
 */
export const SubArchivedBondsListDiffResponse = proto3.makeMessageType(
  "bonds.SubArchivedBondsListDiffResponse",
  () => [
    { no: 1, name: "bond_ids_added", kind: "message", T: BondSet },
    { no: 2, name: "bond_ids_removed", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondCatchupSummariesRequest
 */
export const SubBondCatchupSummariesRequest = proto3.makeMessageType(
  "bonds.SubBondCatchupSummariesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondCatchupSummariesResponse
 */
export const SubBondCatchupSummariesResponse = proto3.makeMessageType(
  "bonds.SubBondCatchupSummariesResponse",
  () => [
    { no: 1, name: "summary", kind: "message", T: UserCatchupSummary, oneof: "summary_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: BondId, oneof: "summary_or_deleted" },
  ],
);

/**
 * @generated from message bonds.SubBondContemporariesRequest
 */
export const SubBondContemporariesRequest = proto3.makeMessageType(
  "bonds.SubBondContemporariesRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "bond_ids", kind: "message", T: BondSet },
  ],
);

/**
 * @generated from message bonds.SubBondContemporariesResponse
 */
export const SubBondContemporariesResponse = proto3.makeMessageType(
  "bonds.SubBondContemporariesResponse",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "bond_contemporaries", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message bonds.CreateFakedataCallForBondRequest
 */
export const CreateFakedataCallForBondRequest = proto3.makeMessageType(
  "bonds.CreateFakedataCallForBondRequest",
  () => [
    { no: 1, name: "call_events", kind: "message", T: RtcSessionEvent, repeated: true },
    { no: 2, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 5, name: "initiator_id", kind: "message", T: UserId },
    { no: 6, name: "bond_id", kind: "message", T: BondId },
    { no: 7, name: "knowledge_preview", kind: "message", T: CallKnowledgePreview },
  ],
);

/**
 * @generated from message bonds.CreateFakedataCallForBondResponse
 */
export const CreateFakedataCallForBondResponse = proto3.makeMessageType(
  "bonds.CreateFakedataCallForBondResponse",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message bonds.SubPersonBondInvitesDiffRequest
 */
export const SubPersonBondInvitesDiffRequest = proto3.makeMessageType(
  "bonds.SubPersonBondInvitesDiffRequest",
  () => [
    { no: 2, name: "person_id", kind: "message", T: PersonId },
  ],
);

/**
 * @generated from message bonds.SubPersonBondInvitesDiffResponse
 */
export const SubPersonBondInvitesDiffResponse = proto3.makeMessageType(
  "bonds.SubPersonBondInvitesDiffResponse",
  () => [
    { no: 1, name: "bond_invites_added", kind: "message", T: BondInvite, repeated: true },
    { no: 2, name: "bond_invites_removed", kind: "message", T: BondInvite, repeated: true },
  ],
);

