import { useCallback, useEffect, useState } from "react";

import { associateUserWithAuthRequest, generatePasscode, getInviteByCode } from "@/api/auth";
import {
    beginAuthenticateWithAuthProvider,
    getLoginRedirectUri,
    relativeUrlForRedirectResponse,
} from "@/auth/login";
import { loadCodeVerifier } from "@/auth/storage";
import { isMobileBrowser } from "@/misc/mobile";
import { getOidcConfig } from "@/misc/oidcConfig";
import { Optional } from "@/misc/types";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import usePrevious from "../hooks/usePrevious";
import { getNativeTarget, isNativePlatform, nativePlatformTargetKey } from "../misc/capacitor";
import { isDevEnv, nativeTargets } from "../misc/environment";
import log from "../misc/log";
import { LocationState } from "./state";
import useRefreshOnLogin from "./useRefreshOnLogin";

// TODO: find some way to hide the native target switcher by default

// NativeTargetSwitcher renders only if the frontend is running natively using
// Capacitor, i.e. using our mobile client.
// It allows users to switch between bo-nd.dev and bondtest.uk.
function NativeTargetSwitcher(): React.JSX.Element {
    const initialTarget = getNativeTarget();
    const [nativeTarget, setNativeTarget] = useState(initialTarget);
    const previousNativeTarget = usePrevious(nativeTarget);

    const toggleNativeTarget = useCallback(() => {
        setNativeTarget(currentTarget =>
            currentTarget === nativeTargets.bondtestUk ? nativeTargets.bondDev
                : nativeTargets.bondtestUk
        );
    }, []);

    useEffect(() => {
        if (previousNativeTarget && previousNativeTarget !== nativeTarget) {
            localStorage.setItem(nativePlatformTargetKey, nativeTarget!);

            // Refresh the page to cause the auth worker
            // to be recreated with the new OIDC authority.
            log.info("Reloading page due to the native target changing");
            window.location.reload();
        }
    }, [nativeTarget, previousNativeTarget]);

    return (
        <>
            <div className="c-signin__element c-signin__element--target">
                {nativeTarget ? nativeTarget : "Current target"}
            </div>
            <button
                className="c-btn-target"
                title="Switch target"
                onClick={toggleNativeTarget}
            >
                Switch target
            </button>
        </>
    );
}

async function beginSignIn() {
    const loginRedirectUri = getLoginRedirectUri();
    const redirectUri = await beginAuthenticateWithAuthProvider(
        await getOidcConfig(loginRedirectUri),
    );

    const response = await fetch(redirectUri, {
        headers: {
            "X-Avos-302-To-200": "true",
        },
    });

    const [relativeUrl, _] = relativeUrlForRedirectResponse(response);

    log.info(`Login flow: next sign-in step ${relativeUrl}`);
    return { loginRedirectUri, relativeUrl };
}

const invitePathPattern = /invite\/([^/]+\/?)$/;

const getInviteCode = (url?: string) => {
    // Get the invite code from the URL e.g. 'http://localhost:8080/invite/1234' -> '1234'
    return url?.match(invitePathPattern)?.[1];
};

async function getInvitedEmail(redirectUrl: Optional<string>, authRequestId: string) {
    try {
        const inviteCode = getInviteCode(redirectUrl);
        if (!inviteCode) {
            return;
        }

        log.info("Invite code found in redirect URL. Fetching invited email...");

        const codeVerifier = await loadCodeVerifier();
        if (!codeVerifier) {
            log.error("No code verifier found");
            return;
        }
        const response = await getInviteByCode(inviteCode, { authRequestId, codeVerifier });

        if (!response.isSuccess) {
            log.error(`Failed to get invited email: ${response.reason}`);
            return;
        }

        return response.response.email;
    }
    catch (e) {
        log.error(`Failed to get invited email`, e);
    }
}

async function continueSignInGeneratePasscode(
    authReqId: string,
    userEmail: string,
    isTargetedInvite: boolean,
): Promise<[string, LocationState]> {
    const codeVerifier = await loadCodeVerifier();

    if (!authReqId || !codeVerifier) {
        throw new Error("No auth request ID or code verifier provided");
    }

    const response = await associateUserWithAuthRequest(authReqId, userEmail, codeVerifier);
    if (!response.isSuccess) {
        throw new Error(`Failed to associate user with auth request: ${response.reason}`);
    }

    const { isNewUser, userId } = response.response;

    const passcodeResp = await generatePasscode(userId, {
        authRequestId: authReqId,
        codeVerifier,
    });
    if (!passcodeResp.isSuccess) {
        throw new Error(`Failed to generate passcode: ${passcodeResp.reason}`);
    }

    const passcodeUrl = `/login/passcode?` +
        new URLSearchParams({ auth_request_id: authReqId }).toString();

    log.info(`Login flow: passcode URL ${passcodeUrl}`);

    return [passcodeUrl, {
        codeVerifier,
        isNewUser,
        isTargetedInvite,
        userId,
        userEmail,
    }];
}

function extractAuthRequestId(relativeUrl: string): string {
    const url = new URL(relativeUrl, "https://example.com");
    const id = url.searchParams.get("auth_request_id");
    if (!id) {
        throw new Error("No auth request ID found in redirect URI: " + relativeUrl);
    }
    return id;
}

function maybeValidEmail(email: string) {
    return z.string().email().safeParse(email).success;
}

function saveDevUser(email: string) {
    if (!isDevEnv) return;
    localStorage.setItem("x-beyond-temp-userid", email);
}

function loadDevUser(): string {
    if (!isDevEnv) return "";
    return localStorage.getItem("x-beyond-temp-userid") || "";
}

export default function LoginView(): React.JSX.Element {
    const navigate = useNavigate();
    const [isFailed, setIsFailed] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);

    const signIn = useCallback(async (email?: string) => {
        try {
            setIsFailed(false);
            setIsSigningIn(true);

            const { loginRedirectUri, relativeUrl } = await beginSignIn();
            const authReqId = extractAuthRequestId(relativeUrl);

            // Sign in with invited email if none provided
            let invitedEmail: Optional<string>;
            if (!email) {
                invitedEmail = await getInvitedEmail(loginRedirectUri, authReqId);
                if (!invitedEmail) {
                    return;
                }
            }

            const signInUserEmail = invitedEmail ?? email;
            if (!signInUserEmail) {
                log.error("No email to sign in with");
                return;
            }

            setUserEmail(signInUserEmail);

            const [passcodeUrl, state] = await continueSignInGeneratePasscode(
                authReqId,
                signInUserEmail,
                invitedEmail !== undefined,
            );

            saveDevUser(signInUserEmail);

            navigate(passcodeUrl, {
                replace: true,
                state: state,
            });
        }
        catch (e) {
            log.error(`Sign-in failed`, e);
            setIsFailed(true);
        }
        finally {
            setIsSigningIn(false);
        }
    }, [navigate, setIsFailed]);

    // Try to sign in with an invite code on initial load
    useEffect(() => {
        const redirUri = getLoginRedirectUri();

        if (getInviteCode(redirUri)) {
            signIn();
        }
        else {
            // Make login on `task dev` a bit more user friendly by having the
            // email field auto-populated at least in the case an invite isn't
            // being followed.
            setUserEmail(loadDevUser());
        }
    }, [signIn]);

    useEffect(() => {
        setContinueButtonEnabled(maybeValidEmail(userEmail));
    }, [userEmail]);

    useRefreshOnLogin();

    const emailInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!continueButtonEnabled || isSigningIn) return;

        if (e.key === "Enter") {
            signIn(userEmail);
        }
    };

    const devEnvPlaceholder = isDevEnv ? " (For dev add @example.com)" : "";
    const isMobile = isMobileBrowser();
    const columnClasses = classNames("c-section-signin__column c-section-signin__column--dark", {
        "c-section-signin__column--mobile": isMobile,
    });
    const signinClasses = classNames("c-signin", {
        "c-signin--desktop": !isMobile,
    });
    const legendClasses = classNames("c-signin__legend", {
        "c-signin__legend--desktop": !isMobile,
    });

    const nativePlatform = isNativePlatform();

    return (
        <main className="">
            <section className="c-section-signin">
                <div className={columnClasses}>
                    <h1 className="c-logo c-logo--signin">Bond</h1>
                </div>
                <div className="c-section-signin__column">
                    <fieldset className={signinClasses}>
                        <h2 className={legendClasses}>
                            Continue with email
                        </h2>
                        <div className="c-signin__element">
                            <input
                                type="email"
                                id="userEmail"
                                value={userEmail}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                placeholder=" "
                                onChange={e => setUserEmail(e.target.value)}
                                onKeyDown={emailInputOnKeyDown}
                            />
                            <label htmlFor="userEmail" className="c-signin__label">
                                {"Email address" + devEnvPlaceholder}
                            </label>
                        </div>
                        <button
                            type="submit"
                            title="Continue"
                            className="c-btn-signin"
                            onClick={() => signIn(userEmail)}
                            disabled={!continueButtonEnabled || isSigningIn}
                        >
                            Continue
                        </button>
                        {isFailed && (
                            <div className="c-signin__error">
                                Error signing in. Please try again.
                            </div>
                        )}
                        <div className="c-signin__meta">
                            By continuing, you agree to our{" "}
                            <a href="/privacy-policy" target="_blank">privacy policy</a> and{" "}
                            <a href="#" target="_blank">terms of use</a>.
                        </div>
                        {nativePlatform && <NativeTargetSwitcher />}
                    </fieldset>
                </div>
            </section>
        </main>
    );
}
