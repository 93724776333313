import type { PrivacyLevel } from "@/api/bonds";
import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";

export interface BondKnowledgePreview {
    aiGeneratedTitle?: string;
    userSpecifiedTitle: string;
    summary: string;
    detailedSummary: string;
    imageUrl: string;
}

// A BondOverview is the full set of Bond information needed to render the
// BondView for that bond. It is the response type for SubBond and GetBonds.
export interface BondOverview {
    id: d.BondId;
    orgId: d.OrgId;
    channelId: d.ChannelId;
    squadIds: Array<d.SquadId>;
    privacy: PrivacyLevel;
    knowledge: BondKnowledgePreview;
    contributors: Array<d.UserId>;
    followers: Array<d.UserId>;
    externalUsers: Array<d.UserId>;
    lastActivityAt: d.Timestamp;
    maxSequenceNumber: number;
    liveCallIds: Array<d.CallId>;
    invitedPersonIds: Array<d.PersonId>;
    invitedEmails: Array<string>;
    creatorId: Optional<d.UserId>;
}

// A BondHerald holds the subset of information about a bond that is needed to
// include or exclude that bond from a list of bonds. It is the response type
// for ListBonds.
export interface BondHerald {
    bondId: d.BondId;
    squadIds: Array<d.SquadId>;
    lastActivityAt: d.Timestamp;
    currentUserIsFollower: boolean;
}

export interface CatchupKnowledge {
    bondId: d.BondId;
    summary: string;
    lastSummarisedSeq: number;
}

export type BondInvitee = {
    case: "email";
    email: string;
} | {
    case: "personId";
    personId: d.PersonId;
};

type Expiry = { expired: true; } | { expired: false; expiresAt: d.Timestamp; };
export type BondInviteTarget = { case: "common"; } | { case: "invitee"; invitee: BondInvitee; };

export type BondInvite = {
    opaqueCode: string;
    bondId: d.BondId;
    originatorId: d.UserId;
    expiry: Expiry;
    createdAt: d.Timestamp;
    target: BondInviteTarget;
    accepted: boolean;
    rejected: boolean;
};

export type BondTitles = {
    userSpecifiedTitle?: string;
    aiGeneratedTitle?: string;
};

export type BondOverviewPredicate = (bo: BondOverview) => boolean;
export const bondHasSquadId = (squadId: d.SquadId): BondOverviewPredicate => bo =>
    bo.squadIds.includes(squadId);
export const bondIsFollowed = (currentUserId: Optional<d.UserId>): BondOverviewPredicate => bo =>
    (currentUserId && bo.followers?.includes(currentUserId)) ?? false;
export const bondIsNotFollowed = (currentUserId: Optional<d.UserId>): BondOverviewPredicate => bo =>
    !((currentUserId && bo.followers?.includes(currentUserId)) ?? false);
export const bondIsArchived = (archivedSet: Set<d.BondId>): BondOverviewPredicate => bo =>
    archivedSet.has(bo.id);
export const bondIsNotArchived = (archivedSet: Set<d.BondId>): BondOverviewPredicate => bo =>
    !archivedSet.has(bo.id);
export const hasImage = (bo: BondOverview) => !!bo.knowledge.imageUrl;

export type BondHeraldPredicate = (bh: BondHerald) => boolean;
export const heraldHasSquadId = (squadId: d.SquadId): BondHeraldPredicate => bh =>
    bh.squadIds.includes(squadId);
export const heraldIsFollowed: BondHeraldPredicate = bh => bh.currentUserIsFollower;
export const heraldIsNotFollowed: BondHeraldPredicate = bh => !bh.currentUserIsFollower;
