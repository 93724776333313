// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file intel/intel.proto (package intel, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { BondId, ChannelId, IntelSummaryStreamingStatus, QueryAnswerStatus, SquadId, UserId } from "../domain/domain_pb.js";
import { BondReadState, ChannelReadState } from "../chats/chats_pb.js";

/**
 * @generated from message intel.SuggestBondTitleRequest
 */
export const SuggestBondTitleRequest = proto3.makeMessageType(
  "intel.SuggestBondTitleRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "bond_content_draft", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.SuggestBondTitleResponse
 */
export const SuggestBondTitleResponse = proto3.makeMessageType(
  "intel.SuggestBondTitleResponse",
  () => [
    { no: 1, name: "bond_title_suggestion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.GetDeltaSummaryBondRequest
 */
export const GetDeltaSummaryBondRequest = proto3.makeMessageType(
  "intel.GetDeltaSummaryBondRequest",
  () => [
    { no: 1, name: "last_read_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "channel_id", kind: "message", T: ChannelId },
    { no: 3, name: "user_id", kind: "message", T: UserId },
    { no: 4, name: "bond_id", kind: "message", T: BondId },
    { no: 5, name: "iana_timezone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.GetDeltaSummaryBondResponse
 */
export const GetDeltaSummaryBondResponse = proto3.makeMessageType(
  "intel.GetDeltaSummaryBondResponse",
  () => [
    { no: 1, name: "max_message_sequence_number", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "delta_summary_bond", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "delta_summary_bond_live", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "first_new_message_ts", kind: "message", T: Timestamp },
    { no: 5, name: "live_session_started_at_ts", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message intel.DeltaSummarySquadMetadata
 */
export const DeltaSummarySquadMetadata = proto3.makeMessageType(
  "intel.DeltaSummarySquadMetadata",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "first_new_message_ts", kind: "message", T: Timestamp },
    { no: 4, name: "bond_read_states", kind: "message", T: BondReadState, repeated: true },
    { no: 6, name: "channel_read_states", kind: "message", T: ChannelReadState, repeated: true },
    { no: 5, name: "last_activity_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message intel.GetDeltaSummarySquadRequest
 */
export const GetDeltaSummarySquadRequest = proto3.makeMessageType(
  "intel.GetDeltaSummarySquadRequest",
  () => [
    { no: 1, name: "squad_id", kind: "message", T: SquadId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "iana_timezone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.GetDeltaSummarySquadResponse
 */
export const GetDeltaSummarySquadResponse = proto3.makeMessageType(
  "intel.GetDeltaSummarySquadResponse",
  () => [
    { no: 1, name: "summary_piece", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "response" },
    { no: 2, name: "progress_status", kind: "enum", T: proto3.getEnumType(IntelSummaryStreamingStatus), oneof: "response" },
    { no: 3, name: "metadata", kind: "message", T: DeltaSummarySquadMetadata, oneof: "response" },
  ],
);

/**
 * @generated from message intel.QueryRequest
 */
export const QueryRequest = proto3.makeMessageType(
  "intel.QueryRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.QueryResponse
 */
export const QueryResponse = proto3.makeMessageType(
  "intel.QueryResponse",
  () => [
    { no: 1, name: "answer_delta", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
    { no: 2, name: "progress_status", kind: "enum", T: proto3.getEnumType(QueryAnswerStatus), oneof: "result" },
    { no: 3, name: "search_results", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "result" },
  ],
);

