import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { CloseButton } from "@/components/buttons/Close";
import { FeatureFlagged } from "@/components/FeatureFlags";
import Avatar, { EmailAvatar } from "@/components/gui/Avatar";
import SensitiveText from "@/components/gui/SensitiveText";
import * as d from "@/domain/domain";
import { userNameForMention } from "@/domain/mentions";
import { SquadOverview } from "@/domain/squads";
import { PersonOverview, UserOverview } from "@/domain/users";
import {
    followBond,
    getShareableBondInviteLinkThunk,
    renameBondTitle,
    selectBondFollowerIds,
    selectBondIsFollower,
    selectBondTitle,
    selectCurrentLiveBondId,
    selectSquadIdsFromBondId,
} from "@/features/bonds";
import { createToast } from "@/features/meta";
import { selectSquadById, selectSquads } from "@/features/squads";
import { selectUsers } from "@/features/users";
import useBondInvitees from "@/hooks/useBondInvitees";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { BondChildRouteContext } from "@/misc/bondChildRouteContext";
import { copyToClipboard } from "@/misc/capacitor";
import log from "@/misc/log";
import { Optional } from "@/misc/types";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux";

const UserEntryContent = (props: { user: UserOverview; }) => (
    <div className="c-suggestion c-suggestion--human">
        <Avatar id={props.user.id} showPresence={false} size="suggestion" />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{userNameForMention(props.user)}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{props.user.name}</SensitiveText>
        </div>
    </div>
);

const InvitedPersonEntryContent = (props: { person: PersonOverview; }) => (
    <div className="c-suggestion c-suggestion--human">
        <Avatar
            id={props.person.personId}
            modifiers={{ invited: true }}
            showPresence={false}
            size="suggestion"
        />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{props.person.name}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{props.person.email}</SensitiveText>
            &nbsp;&bull;&nbsp;Invited
        </div>
    </div>
);

const InvitedEmailEntryContent = (props: { email: string; }) => (
    <div className="c-suggestion c-suggestion--human">
        <EmailAvatar
            email={props.email}
            invited={true}
            size="suggestion"
        />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{props.email}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;Invited
        </div>
    </div>
);

const SquadEntryContent = (props: { squad: SquadOverview; onClick: (id: d.SquadId) => void; }) => (
    <button
        className="c-suggestion c-suggestion--squad"
        onClick={() => props.onClick(props.squad.id)}
    >
        <div className="c-suggestion__squad-icon" />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{props.squad.name}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;{props.squad.userIds.length}&nbsp;people
        </div>
    </button>
);

export default function ModifyBondModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate, navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const [bondNameEntry, setBondNameEntry] = useState(bondTitle.title);

    const followerIds = useSelectorArgs(selectBondFollowerIds, bondId);
    const squadIds = useSelectorArgs(selectSquadIdsFromBondId, bondId);

    const followers = useSelectorArgs(selectUsers, followerIds);
    const squads = useSelectorArgs(selectSquads, squadIds);

    const sortedSquads = useMemo(() => [...squads].sort((a, b) => a.name.localeCompare(b.name)), [
        squads,
    ]);
    const sortedFollowers = useMemo(
        () => [...followers].sort((a, b) => a.name.localeCompare(b.name)),
        [followers],
    );

    const { emails: filteredInvitedEmails, persons: filteredInvitedPersons } = useBondInvitees(
        bondId,
    );

    const currentUserId = useSelectorArgs(selectCurrentUserId);

    const closeModal = useCallback(() => {
        log.debug("Closing modal");
        navigateBack();
    }, [navigateBack]);

    const showUserInvitationAction = useCallback(() => {
        navigate("../invite", { replace: true });
    }, [navigate]);

    const copyShareableLinkToClipboard = useCallback(async () => {
        if (!bondId) {
            return;
        }
        const link = await dispatch(
            getShareableBondInviteLinkThunk({ bondId }),
        ).unwrap().catch(() => {
            dispatch(createToast({
                message: "Failed to get link.",
                duration: { seconds: 3 },
            }));
            throw new Error("Failed to get link.");
        });

        await copyToClipboard(link);

        dispatch(createToast({
            message: "Link copied to clipboard",
            duration: { seconds: 3 },
        }));
    }, [dispatch, bondId]);

    const renameBond = useCallback((title: string) => {
        if (!bondId || !title || !currentUserId || bondTitle.title === title) {
            return;
        }
        dispatch(
            renameBondTitle({
                bondId: bondId,
                title: title,
                actingUserId: currentUserId,
            }),
        );
    }, [bondId, dispatch, currentUserId, bondTitle.title]);

    const [highlightedSquadId, setHighlightedSquadId] = useState<Optional<d.SquadId>>(undefined);
    const highlightedSquad = useSelectorArgs(selectSquadById, highlightedSquadId);
    const highlightedSquadUserIds = highlightedSquad?.userIds || [];
    const highlightedSquadUsers = useSelectorArgs(selectUsers, highlightedSquadUserIds);

    const sortedHighlightedSquadUsers = useMemo(
        () => [...highlightedSquadUsers].sort((a, b) => a.name.localeCompare(b.name)),
        [highlightedSquadUsers],
    );

    const selectSquad = useCallback((squadId: d.SquadId) => {
        setHighlightedSquadId(squadId);
    }, [setHighlightedSquadId]);

    const isFollowing = useSelectorArgs(selectBondIsFollower, bondId);

    // Prevent leaving the bond while in a live session
    const liveBondId = useAppSelector(selectCurrentLiveBondId);
    const cannotLeaveBond = isFollowing && liveBondId == bondId;

    const unfollowBond = useCallback(() => {
        if (!bondId || !isFollowing || cannotLeaveBond) {
            return;
        }
        dispatch(followBond({ bondId, follow: false }));
    }, [dispatch, bondId, isFollowing, cannotLeaveBond]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const highlightedSquadContent = highlightedSquadId ? (
        <>
            <header className="c-dialog__header c-dialog__header--centered">
                <button
                    className="c-btn-dialog-return"
                    onClick={() => setHighlightedSquadId(undefined)}
                >
                    Return
                </button>
                <h1 className="c-dialog__title">{highlightedSquad && highlightedSquad.name}</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content">
                    <div className="c-autocomplete-list c-autocomplete-list--dialog">
                        {sortedHighlightedSquadUsers.map(member => (
                            <UserEntryContent
                                key={member.id}
                                user={member}
                            />
                        ))}
                    </div>
                </div>
            </article>
        </>
    ) : <></>;

    const modifyBondContent = (
        <>
            <header className="c-dialog__header c-dialog__header--centered">
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <div className="c-form-element-new">
                        <input
                            type="text"
                            value={bondNameEntry}
                            onChange={e => setBondNameEntry(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    renameBond(bondNameEntry);
                                }
                            }}
                            className="c-input-new c-input-new--solid c-input-new--full"
                        />
                    </div>

                    <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
                        <div className="c-form-element-new u-m-tb-24">
                            <button
                                className="c-btn-solid c-btn-solid--copy-link"
                                onClick={copyShareableLinkToClipboard}
                            >
                                <div className="c-btn-solid__icon c-btn-solid__icon--link"></div>
                                Copy link
                            </button>
                        </div>
                    </FeatureFlagged>

                    <FeatureFlagged flag={"early-bond-invite-buttons"} match={false}>
                        <br />
                    </FeatureFlagged>

                    <div className="c-dialog-add-people">
                        <button
                            className="c-btn-add-people"
                            onClick={showUserInvitationAction}
                        >
                            <div className="c-btn-add-people__icon" />
                            Add people
                        </button>

                        <div className="c-dialog-add-people__divider" />

                        <div className="c-autocomplete-list c-autocomplete-list--dialog">
                            {sortedSquads.map(squad => (
                                <SquadEntryContent
                                    key={squad.id}
                                    squad={squad}
                                    onClick={selectSquad}
                                />
                            ))}
                            {filteredInvitedEmails.map(email => (
                                <InvitedEmailEntryContent
                                    key={email}
                                    email={email}
                                />
                            ))}
                            {filteredInvitedPersons.map(invitee => (
                                <InvitedPersonEntryContent
                                    key={invitee.personId}
                                    person={invitee}
                                />
                            ))}
                            {sortedFollowers.map(follower => (
                                <UserEntryContent
                                    key={follower.id}
                                    user={follower}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </article>

            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--leave"
                    onClick={unfollowBond}
                    disabled={!isFollowing || cannotLeaveBond}
                    title={cannotLeaveBond ? "You cannot leave this bond while live" : ""}
                >
                    Leave bond
                </button>
            </div>
        </>
    );

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            {highlightedSquadId ? highlightedSquadContent : modifyBondContent}
        </dialog>
    );
}
