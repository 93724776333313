import { PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";

import * as d from "@/domain/domain";
import { DraftTarget, newBondCreationDraftTarget } from "@/domain/draftTarget";
import {
    AppView,
    AppViewStack,
    NavState,
    TopLevelViewName,
    topLevelViewNameSchema,
    viewNames,
    viewNameSchema,
    viewStackSchema,
    viewStackUtils,
    viewToPath,
    viewUtils,
} from "@/domain/views";
import {} from "@/domain/views";
import {
    bondIsNotArchived,
    bondIsNotFollowed,
    BondOverviewPredicate,
    hasImage,
} from "@/domain/bonds";
import { resetStore } from "@/features/auth";
import { Optional } from "@/misc/types";
import { checkPersistor, sessionStorageStore } from "@/persist/shared";
import { storageRead, storageRemove } from "@/persist/storage";
import { HydrateArgs, PersistenceUpdate } from "@/persist/types";
import { createLocalSlice } from "@/store/localSlice";
import { createAppSelector, selectCurrentUserId } from "@/store/redux";
import type { RootState } from "@/store/types";
import { selectArchivedBondsSet, selectBonds } from "./bonds";
import { bondIsUnread, selectAllStagedSequenceNumbers } from "./channels";
import { memoizeOptions, passthroughMemoize } from "./selectors";

const filterOptions = z.enum(["all", "dismissed", "unread"]);
export type FilterOptions = z.infer<typeof filterOptions>;
const discoverFilterOptions = z.enum(["all", "unread"]);
export type DiscoverFilterOptions = z.infer<typeof discoverFilterOptions>;

const _fpState = z.object({
    filter: filterOptions,
    discoverFilter: discoverFilterOptions,
    currentTopLevelViewName: topLevelViewNameSchema,
    viewStacks: z.record(topLevelViewNameSchema, viewStackSchema.optional()),
    // TODO: make zod specification kosher
    updates: z.array(z.custom<PersistenceUpdate>(v => !!v)),
});

export type FilterPanelState = z.infer<typeof _fpState>;

type ViewStackRecord = Partial<Record<TopLevelViewName, AppViewStack>>;

const defaultViewStacks: ViewStackRecord = { inbox: [viewUtils.inbox()] };

const currentViewStack = (state: FilterPanelState) =>
    state.viewStacks[state.currentTopLevelViewName]!;
const currentView = (state: FilterPanelState) => currentViewStack(state).at(-1)!;

/** Give the path that should be linked to, for a given top-level view.
 *
 * - if the top-level view is not the current one, this will be the path of the
 *   head of the appropriate viewstack (or of the view itself, if for some
 *   reason we haven't created one already)
 * - if the top-level view *is* the current one, this will be the path of the
 *   view itself, to allow navigating directly to the top-level view whilst
 *   viewing something further down the stack
 *
 * @param state the `FilterPanelState`
 * @param view the view to query for
 * @return a string for the path to navigate to; `undefined` if the `view` is
 * not a top-level one
 */
const pathForTopLevelView = (
    state: FilterPanelState,
    view: AppView,
): Optional<string> => {
    const topLevelName = viewUtils.toTopLevelName(view);
    if (!topLevelName) return undefined;

    if (topLevelName === state.currentTopLevelViewName) {
        return viewToPath(view);
    }

    // Fallback to the view itself if we haven't generated a viewstack yet.
    const targetView = state.viewStacks[topLevelName]?.at(-1) ?? view;
    return viewToPath(targetView);
};

/** Give the viewstack that would be created by "navigating" to the given view.
 *
 * - if the view is a top-level one, this is the stored viewstack, falling back
 *   to a default one for that top-level view
 * - otherwise, this is the result of pushing the view onto the viewstack
 *
 * @param state the `FilterPanelState`
 * @param view the proposed view for pushing
 * @return a new viewstack; `undefined` if the view is not top-level and would
 * cause an invalid stack to be created.
 */
const proposedStackForViewPush = (
    state: FilterPanelState,
    view: AppView,
): Optional<AppViewStack> => {
    const topLevelName = viewUtils.toTopLevelName(view);
    if (topLevelName) {
        return state.viewStacks[topLevelName] ?? viewStackUtils.defaultStackForView(view);
    }

    const stack = currentViewStack(state);
    const p = viewStackSchema.safeParse([...stack, view]);
    return p.success ? p.data : undefined;
};

const resetSquadSummariesAtTopOfViewStack = (viewStacks: ViewStackRecord): void => {
    Object.values(viewStacks).filter(v => viewUtils.hasAnySquad(v)).forEach(
        stack => {
            const frame = stack?.at(-1);
            if (viewUtils.isSquad(frame)) {
                frame.summary = false;
            }
        },
    );
};

const getInitialState = (
    props?: Partial<FilterPanelState>,
): FilterPanelState => ({
    filter: props?.filter ?? "all",
    discoverFilter: props?.discoverFilter ?? "all",
    currentTopLevelViewName: props?.currentTopLevelViewName ?? viewNameSchema.Enum.inbox,
    viewStacks: props?.viewStacks ?? defaultViewStacks,
    updates: [],
});

// TODO: sanitise viewstack on first load
// TODO: persist viewstack on first load?

const filterPanelSlice = createLocalSlice({
    name: "filterPanel",
    initialState: getInitialState(),
    selectors: {
        filter: state => state.filter,
        discoverFilter: state => state.discoverFilter,
        currentTopLevelView: state => currentViewStack(state)[0],
        currentTopLevelViewName: state => state.currentTopLevelViewName,
        viewForNewBondDraft: state => {
            const stack = currentViewStack(state);
            const view = stack[0];
            if (!viewUtils.isMySquads(view)) return view;
            return stack[1];
        },

        proposedStackForViewPush,
        proposedPoppedStack: state => {
            const stack = currentViewStack(state);
            return stack.length > 1 ? stack.slice(0, -1) : stack;
        },

        currentViewStack,
        currentView,

        pathForTopLevelView,

        inboxSelected: state => viewUtils.hasInbox(currentViewStack(state)),
        discoverSelected: state => viewUtils.hasDiscover(currentViewStack(state)),
        squadSelected: (state, id: d.SquadId) => viewUtils.hasSquad(currentViewStack(state), id),
    },
    reducers: {
        setFilter: (state, { payload: filter }: PayloadAction<FilterOptions>) => {
            state.filter = filter;
            state.updates = [[stores.filterOptions, filter]];
        },
        /** Switch to a top-level view.
         *
         * Be careful that you only call this with the possible top-level views
         * for your given environment, e.g. don't call this with the squad view
         * from "my squads".
         *
         * Use `pushViewStack`/`popViewStack` for navigation under the top-level
         * view.
         */
        switchViewStack: (state, { payload: view }: PayloadAction<AppView>) => {
            const name = viewUtils.toTopLevelName(view);
            if (!name) return;

            const updates: PersistenceUpdate[] = [];

            if (name !== state.currentTopLevelViewName) {
                state.currentTopLevelViewName = name;
                updates.push([stores.currentTopLevelViewName, name]);
                resetSquadSummariesAtTopOfViewStack(state.viewStacks);
            }

            if (!state.viewStacks[name]) {
                state.viewStacks[name] = [view];
                updates.push([stores.viewStacks, state.viewStacks]);
            }

            if (updates.length > 0) {
                state.updates = updates;
            }
        },
        replaceAndSwitchViewStack: (state, { payload: stack }: PayloadAction<AppViewStack>) => {
            const name = viewUtils.toTopLevelName(stack[0]);
            if (!name) return;

            const updates: PersistenceUpdate[] = [];

            if (name !== state.currentTopLevelViewName) {
                state.currentTopLevelViewName = name;
                updates.push([stores.currentTopLevelViewName, name]);
                resetSquadSummariesAtTopOfViewStack(state.viewStacks);
            }

            const originalStack = state.viewStacks[name];

            const p = viewStackSchema.safeParse(stack);
            if (!p.success) return;
            if (!originalStack || !viewStackUtils.viewStacksEqual(originalStack, p.data)) {
                state.viewStacks[name] = p.data;
                updates.push([stores.viewStacks, state.viewStacks]);
            }

            if (updates.length > 0) {
                state.updates = updates;
            }
        },
        pushViewStack: (state, { payload: view }: PayloadAction<AppView>) => {
            const stack = currentViewStack(state);

            if (!stack || !viewStackSchema.safeParse([...stack, view]).success) return;

            stack.push(view);
            state.updates.push([stores.viewStacks, state.viewStacks]);
        },
        popViewStack: state => {
            const stack = currentViewStack(state);

            if (!stack || stack.length === 1) return;

            stack.pop();
            state.updates.push([stores.viewStacks, state.viewStacks]);
        },
        setDiscoverFilter: (state, action: PayloadAction<DiscoverFilterOptions>) => {
            state.discoverFilter = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    setFilter,
    setDiscoverFilter,
    switchViewStack,
    pushViewStack,
    popViewStack,
    replaceAndSwitchViewStack,
} = filterPanelSlice.actions;

const selectors = filterPanelSlice.getSelectors((state: RootState) => state.filterPanel);
export const {
    filter: selectFilter,
    discoverFilter: selectDiscoverFilter,
    currentTopLevelView: selectCurrentTopLevelView,
    currentTopLevelViewName: selectCurrentTopLevelViewName, // exported only for tests
    currentView: selectCurrentView, // exported only for tests
    currentViewStack: selectCurrentViewStack,
    inboxSelected: selectInboxSelected,
    discoverSelected: selectDiscoverSelected,
    squadSelected: selectSquadSelected,
    pathForTopLevelView: selectTestPathForTopLevelView, // TODO: remove in favour of the combined one?
} = selectors;

const viewForDraftTargetForStack = (stack: AppViewStack): AppView => {
    const view = stack[0];
    if (!viewUtils.isMySquads(view)) return view;
    return stack[1];
};

const toBondCreationDraftTarget = (view: AppView): DraftTarget => {
    switch (view.view) {
        case viewNames.inbox:
        case viewNames.discover:
            return newBondCreationDraftTarget(view.view);
        case viewNames.squad:
            return newBondCreationDraftTarget(viewUtils.topLevelSquadName(view));
    }
    throw new Error(`Invalid view for bond creation: ${view}`);
};

export const selectDraftTargetForBondCreationFromCurrentView = createAppSelector(
    [selectors.currentViewStack],
    stack => {
        const view = viewForDraftTargetForStack(stack);
        return toBondCreationDraftTarget(view);
    },
    memoizeOptions.weakMap,
);

// Hackity hack. A quick and dirty frontend only implementation of bonds we might like
// to discover.
export const selectBondIdsForDiscover = createAppSelector(
    [
        state => selectBonds(state),
        selectCurrentUserId,
        state => selectArchivedBondsSet(state),
        state => selectAllStagedSequenceNumbers(state),
        state => selectDiscoverFilter(state),
    ],
    (previews, currentUserId, archivedSet, stagedSeq, filter) => {
        const predicates: BondOverviewPredicate[] = [];

        if (filter === "unread") {
            predicates.push(bondIsUnread(stagedSeq));
        }

        predicates.push(bondIsNotArchived(archivedSet));
        predicates.push(bondIsNotFollowed(currentUserId));
        predicates.push(hasImage);

        return previews.filter(bond => predicates.every(p => p(bond))).map(bond => bond.id);
    },
);

/** Get the viewstack that would be created if navigating to `view`.
 *
 * This needs to be memoized to avoid unnecessary recalculations downstream.
 *
 * We could run the logic in the implementation of this selector, but then we
 * would have to peer inside the slice's state (which we have managed to avoid
 * to date for all our selectors).
 *
 * We could have the exported selector apply the memoisation, but then there
 * are questions raised about how to manage the memoisation-object that owns
 * the caching.
 *
 * Rather than work out the Correct Approach for the latter, just pass-through
 * the value from the exported selector, and apply the memoisation.
 */
const selectProposedStackForViewPush = passthroughMemoize(
    selectors.proposedStackForViewPush,
    memoizeOptions.weakMapShallow,
);

/** Get the information required to navigate using the viewstack hierarchy.
 *
 * This is:
 * - the path to navigate to, if the view is top-level (`""` for
 *   non-top-level views)
 * - the viewstack to attach as URL state
 *
 * @param state
 * @param view the view to which we may wish to navigate
 * @return a pair of a path and a viewstack to attach as URL state; the viewstack
 * will be `undefined` if the view is not a valid to push onto the current viewstack
 */
export const selectViewLinkPathAndViewStack = createAppSelector([
    selectors.pathForTopLevelView,
    selectProposedStackForViewPush,
    (_, view: AppView) => view,
], (targetPath, proposedViewStack, view): [string, Optional<NavState>] => {
    targetPath ??= "";

    if (!proposedViewStack) return ["", undefined];

    const viewPath = viewToPath(view);

    // This is the case: we're viewing a bond under a squad S, and pressing the
    // squad entry for S should take you back to viewing all the bonds in that
    // squad.
    if (targetPath === viewPath) {
        const viewStack = viewStackUtils.defaultStackForView(view);
        return [targetPath, viewStack && { viewStack }];
    }

    // targetPath === "" => not top-level, so use viewPath instead
    // We return a string because `Link` components require a `to` value that
    // is not `undefined`.
    const target = targetPath || viewPath || "";
    return [target, { viewStack: proposedViewStack }];
}, memoizeOptions.weakMap);

/** Given a viewStack, find the highest single bond in it.
 */
export const selectTopSingleBond = createAppSelector(
    [
        selectViewLinkPathAndViewStack,
    ],
    ([_, navState]) => navState?.viewStack.find(viewUtils.isSingleBond),
    memoizeOptions.weakMap,
);

export const reducer = filterPanelSlice.reducer;

// Persistence.

const stores = {
    _filters: sessionStorageStore<any>("filter-panel/filters", 1, 20),
    filterOptions: sessionStorageStore<FilterOptions>("filter-panel/options", 21),
    currentTopLevelViewName: sessionStorageStore<TopLevelViewName>(
        "filter-panel/current-view-name",
        21,
    ),
    viewStacks: sessionStorageStore<ViewStackRecord>("filter-panel/view-stacks", 26),
};

export const persistor = {
    stores,
    persist: (previous: FilterPanelState, next: FilterPanelState): PersistenceUpdate[] => {
        return next.updates !== previous.updates ? next.updates : [];
    },
    hydrate: async ({ storage }: HydrateArgs) => {
        if (!storage) return getInitialState();

        const filter = storageRead(stores.filterOptions);
        const currentTopLevelViewName = storageRead(stores.currentTopLevelViewName);
        const viewStacks = storageRead(stores.viewStacks);

        return getInitialState({ filter, currentTopLevelViewName, viewStacks });
    },
    purge: () => {
        storageRemove(stores.filterOptions);
        storageRemove(stores.currentTopLevelViewName);
        storageRemove(stores.viewStacks);
    },
};
checkPersistor<FilterPanelState>(persistor);
