import classNames from "classnames";
import { useCallback, useMemo } from "react";

import SensitiveText from "@/components/gui/SensitiveText";
import LinkToView from "@/components/navigation/LinkToView";
import * as d from "@/domain/domain";
import { viewUtils } from "@/domain/views";
import { selectHeraldsInSquad } from "@/features/bonds";
import { selectSquadIsUnreadAsOf } from "@/features/channels";
import { selectSquadSelected, selectTopSingleBond } from "@/features/filterPanel";
import { SearchFilterOptions, setFilter, setQuery } from "@/features/search";
import { selectSquadById, selectSquadDominantPresenceIcon } from "@/features/squads";
import { useInterestedBond, useInterestedBonds } from "@/hooks/interest/useInterest";
import useDebounce from "@/hooks/useDebounce";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { backendRTTDebounceDuration } from "@/misc/backend";
import { useAppDispatch } from "@/store/redux";

function SquadEntry(
    { id }: { id: d.SquadId; },
) {
    const squad = useSelectorArgs(selectSquadById, id);

    const dispatch = useAppDispatch();
    const thisSquadSelected = useSelectorArgs(selectSquadSelected, id);
    const squadView = useMemo(() => viewUtils.squad(id), [id]);

    // Register interest in all bonds in this squad, if selected
    const squadHeralds = useSelectorArgs(selectHeraldsInSquad, id);
    const selectedSquadBondIds = useMemo(() => {
        const selectedSquadHeralds = (thisSquadSelected && squadHeralds) || [];
        return selectedSquadHeralds.map(h => h.bondId);
    }, [thisSquadSelected, squadHeralds]);
    useInterestedBonds(selectedSquadBondIds);

    // Register interest in any bond 'open' in this squad - i.e. the top bond in the viewStack.
    const topSingleBond = useSelectorArgs(selectTopSingleBond, squadView);
    useInterestedBond(topSingleBond?.id);

    const presenceIconString = useSelectorArgs(selectSquadDominantPresenceIcon, id);

    const squadIsUnreadAsOf = useSelectorArgs(selectSquadIsUnreadAsOf, id);
    const squadIsUnread = squadIsUnreadAsOf != false;
    const debouncedSquadIsUnread = useDebounce(
        squadIsUnread,
        backendRTTDebounceDuration,
        undefined,
        (_, newValue) => !newValue,
    );
    const clearSearch = useCallback(() => {
        dispatch(setFilter(SearchFilterOptions.Everything));
        dispatch(setQuery(""));
    }, [dispatch]);

    if (!squad) {
        return <></>;
    }

    const classes = classNames("c-squad-listing__squad", {
        "is-selected": thisSquadSelected,
        "is-unread": debouncedSquadIsUnread,
    });

    return (
        <LinkToView view={squadView} className={classes} onClick={clearSearch}>
            <span className="c-squad-label">
                <SensitiveText>{squad.name}</SensitiveText>
            </span>

            {presenceIconString && (
                <span
                    className="c-icon-animated"
                    style={{
                        backgroundImage: `url("${presenceIconString}")`,
                    }}
                />
            )}
        </LinkToView>
    );
}

export default SquadEntry;
