import * as d from "@/domain/domain";
import { nanoid } from "@reduxjs/toolkit";

export type QueryId = `qry-id:${string}`;
export function newQueryId(): QueryId {
    return `qry-id:${nanoid()}`;
}

// Hysteresis rules for bond title suggestion
const isStringLengthHysteresisMet =
    (triggerValue: number) => (oldValue: string, newValue: string) => {
        const lengthDelta = Math.abs(oldValue.trim().length - newValue.trim().length);
        return lengthDelta >= triggerValue;
    };

const messageLengthDiffTrigger = 20;
const isMessageHysteresisMet = isStringLengthHysteresisMet(messageLengthDiffTrigger);

export const minimumTriggerMessageLength = 70;
export const messageHysteresisBondTitleSuggestion = (oldValue: string, newValue: string) => {
    if (!newValue) return true;
    const newValueWithoutLastWord = removeLastWord(newValue);
    if (newValueWithoutLastWord.length < minimumTriggerMessageLength) return false;

    return isMessageHysteresisMet(
        removeLastWord(oldValue),
        newValueWithoutLastWord,
    );
};

function removeLastWord(draftText: string): string {
    // Split on newline, space and tab
    const parts = draftText.split(/\s+/);
    return parts.slice(0, -1).join(" ");
}

export interface DeltaKnowledgeBond {
    bondId: d.BondId;
    deltaSummaryBond: string[];
    deltaSummaryBondLive: string[];
    lastReadSeqNumber: number;
    lastSummarisedSeq: number;
    firstNewMessageTs: number;
    liveSessionStartedAt?: number;
}

export interface DeltaSummarySquadMetadata {
    SquadId: d.SquadId;
    TimeOfFirstMessage: number;
    ChannelReadStates: ChannelReadStates[];
    LastActivityAt: number;
}

export interface ChannelReadStates {
    ChannelId: d.ChannelId;
    LastReadSequenceNumber: number;
    MaxMessageSequenceNumber: number;
}

export interface DeltaKnowledgeSquad extends DeltaSummarySquadMetadata {
    Summary?: string;
}

// I placed this enum here. It is probably not the best place to have it.
export enum SummaryRequestStatus {
    Unspecified = 1,
    ProcessingRequest,
    Done,
    Error,
}
