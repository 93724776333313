import classNames from "classnames";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { saveLoginRedirectUri } from "@/auth/login";
import BadgeCountUpdater from "@/components/BadgeCountUpdater";
import Capacitor from "@/components/Capacitor";
import { Footer } from "@/components/Footer";
import Header from "@/components/Header";
import NotificationFeed from "@/components/NotificationFeed";
import { SentryReportDialog } from "@/components/SentryReportButton";
import { SystemMessage } from "@/components/SystemMessage";
import { pathToView, viewUtils } from "@/domain/views";
import { AuthStatus, selectAuthStatus, updateAuthStatus } from "@/features/auth";
import { selectIdbUpgradeRequired, selectShowSidebar } from "@/features/meta";
import { useSelfInterest } from "@/hooks/interest/useInterest";
import { isNativePlatform } from "@/misc/capacitor";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import { SidebarView } from "@/views/SidebarView";
import { useDispatch } from "react-redux";

function IDBUpgrader(): React.JSX.Element {
    // There's probably a better place to put this, but I've put
    // it here for lack of imagination of where that place is.
    const idbUpgradeNeeded = useAppSelector(selectIdbUpgradeRequired);
    useEffect(() => {
        if (idbUpgradeNeeded) {
            log.warn("IndexedDB upgrade required, about to reload page");
            new Promise(r => setTimeout(r, 1000)).then(() => location.reload());
        }
    }, [idbUpgradeNeeded]);

    return <></>;
}

function MainContainer({ children }: { children: React.ReactNode; }) {
    const isMobile = isMobileBrowser();
    const sidebarToggleEnabled = useAppSelector(selectShowSidebar);
    const { pathname } = useLocation();
    const view = pathToView(pathname);
    const showSidebar = isMobile ? viewUtils.isInbox(view) : sidebarToggleEnabled;

    const wrapperClasses = classNames("l-wrapper", {
        "l-wrapper--desktop": !isMobile,
    });

    return (
        <div className={wrapperClasses}>
            {showSidebar && <SidebarView />}
            <main className="l-main">
                <Header />
                {children}
                <Footer />
            </main>
        </div>
    );
}

export function RootView(): React.JSX.Element {
    useSelfInterest();
    const nativePlatform = isNativePlatform();
    return (
        <>
            {!nativePlatform && <NotificationFeed />}
            {nativePlatform && <Capacitor />}
            <IDBUpgrader />
            <SentryReportDialog />
            <BadgeCountUpdater />
            <MainContainer>
                <Outlet />
            </MainContainer>
        </>
    );
}

export function AuthenticatedRootView(): React.JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authStatus = useAppSelector(selectAuthStatus);

    useEffect(() => {
        switch (authStatus) {
            case AuthStatus.Unauthenticated:
                log.info("User is not authenticated, clearing auth", AuthStatus[authStatus]);
                dispatch(updateAuthStatus(AuthStatus.ClearingAuthentication));
                break;
            case AuthStatus.ClearingAuthentication:
                log.info("User is not authenticated, waiting for auth state to clear");
                break;
            case AuthStatus.LoggedOutReloadRequired:
                log.info("User logged out, loading /login");
                saveLoginRedirectUri(new URL(window.location.href).pathname);
                window.location.href = "/login";
        }
    }, [authStatus, dispatch, navigate]);

    if (authStatus === AuthStatus.NotStarted) {
        return <SystemMessage message="Initialising..." />;
    }

    if (authStatus === AuthStatus.Unauthenticated) {
        return <SystemMessage message="Loading external login UI..." />;
    }

    return <RootView />;
}
