import { Timestamp as ProtoTimestamp } from "@bufbuild/protobuf";
import * as uuidLib from "uuid";

import * as dcg from "../../gen/proto/domain/domain_pb";

import { AudienceAction, AudienceOp, isSquadId } from "@/domain/audience";
import * as d from "@/domain/domain";
import type { RaPID, RawRtcSessionId, Tagged, UUID } from "@/domain/uuid";
import {
    extractRawPersonId,
    extractRawRtcSessionId,
    extractUUID,
    validateRawPersonId,
    validateRawRtcSessionId,
    validateUUID,
} from "@/domain/uuid";
import log from "@/misc/log";
import { Optional } from "@/misc/types";

export function lenientFromProtoUuid(v: dcg.UUID | undefined): UUID | undefined {
    if (!v?.value) {
        return undefined;
    }

    return validateUUID(uuidLib.stringify(v.value));
}

export function fromProtoUuid(v: dcg.UUID | undefined): UUID {
    if (!v?.value) {
        const s = `invalid protobuf UUID: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return validateUUID(uuidLib.stringify(v.value));
}

export function toProtoUuid(u: UUID | undefined): dcg.UUID {
    if (!u) {
        const s = `invalid string UUID: ${JSON.stringify(u)}`;
        log.info(s);
        throw new Error(s);
    }

    return new dcg.UUID({
        value: uuidLib.parse(u),
    });
}

export function fromProtoBlobId(v: dcg.BlobId | undefined): d.BlobId {
    if (!v?.value) {
        const s = `invalid protobuf BlobId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:blob:${fromProtoUuid(v.value)}`;
}

export function fromProtoBondId(v: dcg.BondId | undefined): d.BondId {
    if (!v?.value) {
        const s = `invalid protobuf BondId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:bond:${fromProtoUuid(v.value)}`;
}

export function fromProtoCallId(v: dcg.CallId | undefined): d.CallId {
    if (!v?.value) {
        const s = `invalid protobuf CallId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:call:${fromProtoUuid(v.value)}`;
}

export function fromProtoChannelId(v: dcg.ChannelId | undefined): d.ChannelId {
    if (!v?.value) {
        const s = `invalid protobuf ChannelId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:channel:${fromProtoUuid(v.value)}`;
}

export function fromProtoMessageId(v: dcg.MessageId | undefined): d.MessageId {
    if (!v?.value) {
        const s = `invalid protobuf MessageId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:message:${fromProtoUuid(v.value)}`;
}

export function fromProtoNotifId(v: dcg.NotifId | undefined): d.NotificationId {
    if (!v?.value) {
        const s = `invalid protobuf NotificationId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:notification:${fromProtoUuid(v.value)}`;
}

export function fromProtoOrgId(v: dcg.OrgId | undefined): d.OrgId {
    if (!v?.value) {
        const s = `invalid protobuf OrgId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:org:${fromProtoUuid(v.value)}`;
}

export function fromProtoRtcParticipantId(v: dcg.RtcParticipantId | undefined): d.RtcParticipantId {
    if (!v?.value) {
        const s = `invalid protobuf RtcParticipantId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:participant:${fromProtoUuid(v.value)}`;
}

export function fromProtoPersonId(v: dcg.PersonId | undefined): d.PersonId {
    if (!v) {
        const s = `empty participant ID used as PersonId`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:person:${validateRawPersonId(v.value)}`;
}

export function fromProtoPersonSet(v: dcg.PersonSet | undefined): d.PersonId[] {
    if (!v) {
        return [];
    }
    return v.ids.map(fromProtoPersonId);
}

export function fromProtoRtcSessionId(v: dcg.RtcSessionId | undefined): d.RtcSessionId {
    if (!v?.value) {
        const s = `invalid protobuf RtcSessionId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:session:${validateRawRtcSessionId(v.value)}`;
}

export function fromProtoSquadId(v: dcg.SquadId | undefined): d.SquadId {
    if (!v?.value) {
        const s = `invalid protobuf SquadId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:squad:${fromProtoUuid(v.value)}`;
}

export function fromOptionalProtoUserId(v: dcg.UserId | undefined): Optional<d.UserId> {
    if (v === undefined) {
        return;
    }

    return fromProtoUserId(v);
}

export function fromProtoUserId(v: dcg.UserId | undefined): d.UserId {
    if (!v?.value) {
        const s = `invalid protobuf UserId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:user:${fromProtoUuid(v.value)}`;
}

export function fromProtoDeviceId(v: dcg.DeviceId | undefined): d.DeviceId {
    if (!v?.value) {
        const s = `invalid protobuf UserId: ${JSON.stringify(v)}`;
        log.info(s);
        throw new Error(s);
    }
    return `urn:beyond:device:${fromProtoUuid(v.value)}`;
}

export function toProtoBondSet(v: d.BondId[] | undefined): dcg.BondSet {
    if (!v) return new dcg.BondSet();

    return new dcg.BondSet({ ids: v.map(pbBondId) });
}

export function fromProtoBondSet(v: dcg.BondSet | undefined): d.BondId[] {
    if (!v) {
        return [];
    }
    return v.ids.map(fromProtoBondId);
}

export function toProtoCallIdSet(v: d.CallId[] | undefined): dcg.CallSet {
    if (!v) return new dcg.CallSet();

    return new dcg.CallSet({ ids: v.map(pbCallId) });
}

export function fromProtoCallIdSet(v: dcg.CallSet | undefined): d.CallId[] {
    if (!v) {
        return [];
    }
    return v.ids.map(fromProtoCallId);
}

export function toProtoOrgSet(v: d.OrgId[] | undefined): dcg.OrgSet {
    if (!v) return new dcg.OrgSet();

    return new dcg.OrgSet({ ids: v.map(pbOrgId) });
}

export function toProtoUserSet(v: d.UserId[] | undefined): dcg.UserSet {
    if (!v) return new dcg.UserSet();

    return new dcg.UserSet({ ids: v.map(pbUserId) });
}

export function fromProtoUserSet(v: dcg.UserSet | undefined): d.UserId[] {
    if (!v) {
        return [];
    }
    return v.ids.map(fromProtoUserId);
}

export function toProtoSquadSet(v: d.SquadId[] | undefined): dcg.SquadSet {
    if (!v) return new dcg.SquadSet();

    return new dcg.SquadSet({ ids: v.map(pbSquadId) });
}

export function fromProtoSquadSet(v: dcg.SquadSet | undefined): d.SquadId[] {
    if (!v) {
        return [];
    }
    return v.ids.map(fromProtoSquadId);
}

export function toProtoPersonSet(v: d.PersonId[] | undefined): dcg.PersonSet {
    if (!v) return new dcg.PersonSet();

    return new dcg.PersonSet({ ids: v.map(pbPersonId) });
}

export function fromProtoTimestamp(t: ProtoTimestamp | undefined): d.Timestamp {
    if (!t) {
        return 0;
    }

    // TODO: Stop throwing away nanos
    const s = t.seconds * 1000n;
    const truncated = BigInt.asIntN(53, s).valueOf();
    return Number(truncated);
}

export function pbTimestamp(t: d.Timestamp | undefined): ProtoTimestamp {
    if (!t) {
        return new ProtoTimestamp({});
    }

    const ms = t % 1000;
    const s = (t - ms) / 1000;
    return new ProtoTimestamp({
        seconds: BigInt(s),
        nanos: ms * 1e6,
    });
}

export function pbAudienceAction(audienceAction: AudienceAction): dcg.AudienceAction {
    switch (audienceAction) {
        case AudienceAction.Add:
            return dcg.AudienceAction.ADD;
        case AudienceAction.AddNotify:
            return dcg.AudienceAction.ADD_NOTIFY;
        case AudienceAction.Remove:
            return dcg.AudienceAction.REMOVE;
        case AudienceAction.Unspecified:
        default:
            return dcg.AudienceAction.UNSPECIFIED;
    }
}

export function pbAudienceOp(audienceOp: AudienceOp): dcg.AudienceOp {
    const action = pbAudienceAction(audienceOp.action);

    if (isSquadId(audienceOp.target)) {
        return new dcg.AudienceOp({
            action,
            target: {
                case: "squadId",
                value: pbSquadId(audienceOp.target),
            },
        });
    }

    // isUserId(audienceOp.member) == true
    return new dcg.AudienceOp({
        action,
        target: {
            case: "userId",
            value: pbUserId(audienceOp.target),
        },
    });
}

const protoExtractUUID = <T extends Tagged<UUID, string>>(id: T) => ({
    value: toProtoUuid(extractUUID(id)),
});
const protoExtractPersonIdString = <T extends Tagged<RaPID, string>>(id: T) => ({
    value: extractRawPersonId(id),
});
const protoExtractRtcSessionIdString = <T extends Tagged<RawRtcSessionId, string>>(id: T) => ({
    value: extractRawRtcSessionId(id),
});

export function pbBlobId(id: d.BlobId): dcg.BlobId {
    return new dcg.BlobId(protoExtractUUID(id));
}

export function pbUserId(id: d.UserId): dcg.UserId {
    return new dcg.UserId(protoExtractUUID(id));
}

export function pbPersonId(id: d.PersonId): dcg.PersonId {
    return new dcg.PersonId(protoExtractPersonIdString(id));
}

export function pbOrgId(id: d.OrgId): dcg.OrgId {
    return new dcg.OrgId(protoExtractUUID(id));
}

export function pbSquadId(id: d.SquadId): dcg.SquadId {
    return new dcg.SquadId(protoExtractUUID(id));
}

export function pbBondId(id: d.BondId): dcg.BondId {
    return new dcg.BondId(protoExtractUUID(id));
}

export function pbCallId(id: d.CallId): dcg.CallId {
    return new dcg.CallId(protoExtractUUID(id));
}

export function pbChannelId(id: d.ChannelId): dcg.ChannelId {
    return new dcg.ChannelId(protoExtractUUID(id));
}

export function pbMessageId(id: d.MessageId): dcg.MessageId {
    return new dcg.MessageId(protoExtractUUID(id));
}

export function pbRtcParticipantId(id: d.RtcParticipantId): dcg.RtcParticipantId {
    return new dcg.RtcParticipantId(protoExtractUUID(id));
}

export function pbRtcSessionId(id: d.RtcSessionId): dcg.RtcSessionId {
    return new dcg.RtcSessionId(protoExtractRtcSessionIdString(id));
}

export function pbSentryCorrelationId(id: d.SentryCorrelationId): dcg.SentryCorrelationId {
    return new dcg.SentryCorrelationId(protoExtractUUID(id));
}

export function pbNotifId(id: d.NotificationId): dcg.NotifId {
    return new dcg.NotifId(protoExtractUUID(id));
}

export function pbSquadSet(ids: d.SquadId[]): dcg.SquadSet {
    return new dcg.SquadSet({ ids: ids.map(pbSquadId) });
}

export function pbUserSet(ids: d.UserId[]): dcg.UserSet {
    return new dcg.UserSet({ ids: ids.map(pbUserId) });
}

export function pbAudienceOpSet(audienceOps: AudienceOp[]): dcg.AudienceOpSet {
    return new dcg.AudienceOpSet({ audienceOps: audienceOps.map(pbAudienceOp) });
}

export function pbBondSet(ids: d.BondId[]): dcg.BondSet {
    return new dcg.BondSet({ ids: ids.map(pbBondId) });
}

export function pbCallSet(ids: d.CallId[]): dcg.CallSet {
    return new dcg.CallSet({ ids: ids.map(pbCallId) });
}

export function pbBlobSet(ids: d.BlobId[]): dcg.BlobSet {
    return new dcg.BlobSet({ ids: ids.map(pbBlobId) });
}

export function pbPersonSet(ids: d.PersonId[]): dcg.PersonSet {
    return new dcg.PersonSet({ ids: ids.map(pbPersonId) });
}

export function bigintToNumber(v: bigint): number {
    const truncated = BigInt.asIntN(53, v).valueOf();
    return Number(truncated);
}
