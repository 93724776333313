import { FeatureFlagged } from "@/components/FeatureFlags";
import BondPrivacyDomain from "@/components/gui/BondPrivacyDomain";
import SensitiveText from "@/components/gui/SensitiveText";
import TimeAgo from "@/components/gui/TimeAgo";
import PresenceHeader from "@/components/PresenceHeader";
import * as d from "@/domain/domain";
import { isCallStartMessage, isOfficialChatMessage } from "@/domain/messages";
import {
    AttachmentSearchResult,
    BondSearchResult,
    CallSearchResult,
    isAttachmentSearchResult,
    isBondSearchResult,
    isCallSearchResult,
    isMessageSearchResult,
    MessageSearchResult,
    SearchResult,
} from "@/domain/search";
import { selectBondById, selectBondTitle } from "@/features/bonds";
import { selectCallById } from "@/features/calls";
import { selectMessage } from "@/features/chats";
import {
    selectFilter,
    selectHighlightMarks,
    selectQuery,
    selectResults,
    setFilter,
} from "@/features/search";
import { SearchFilterOptions } from "@/features/search";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import classNames from "classnames";
import React, { MouseEventHandler, PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "./BondsListView";

const FilterButton = (
    { name, current, onClick }: {
        name: SearchFilterOptions;
        current: SearchFilterOptions;
        onClick: (nf: SearchFilterOptions) => void;
    },
) => {
    const classes = classNames("c-filter-dropdown__option", {
        "is-selected": current === name,
    });
    return (
        <button
            className={classes}
            onClick={() => onClick(name)}
        >
            {name}
        </button>
    );
};

function SearchFilterAndPresenceHeader(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const filter = useAppSelector(selectFilter);
    const isFilterEnabled = filter !== SearchFilterOptions.Everything;
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilter: MouseEventHandler = () => setIsFilterVisible(z => !z);
    const handleFilterChange = (newFilter: SearchFilterOptions) => {
        dispatch(setFilter(newFilter));
        setIsFilterVisible(false);
    };

    const buttonClasses = classNames("c-btn-filter", {
        "is-selected": isFilterEnabled,
    });
    const divClasses = classNames("c-filter-dropdown__options", {
        "is-visible": isFilterVisible,
    });

    const options: SearchFilterOptions[] = [
        SearchFilterOptions.Everything,
        SearchFilterOptions.Messages,
        SearchFilterOptions.Bonds,
        SearchFilterOptions.Calls,
        SearchFilterOptions.Attachments,
    ];

    return (
        <div className="c-bonds-presence-wrapper">
            <div className="c-filter-dropdown">
                <button
                    onClick={toggleFilter}
                    className={buttonClasses}
                    title={`Showing ${filter} bonds`}
                >
                    {filter}
                </button>

                <div className={divClasses}>
                    {options.map(name => (
                        <FilterButton
                            name={name}
                            current={filter}
                            onClick={handleFilterChange}
                            key={name}
                        />
                    ))}
                </div>
            </div>
            <PresenceHeader />
        </div>
    );
}

export function ShowSearchResultsWhenAvailable({ children }: PropsWithChildren): React.JSX.Element {
    const query = useSelector(selectQuery);

    if (query.length > 0) return <SearchView />;
    return <>{children}</>;
}

function parseHTML(str: string, marks: [string, string]): JSX.Element[] {
    // mark is of the format [hl: random]. We should escape the brackets and the colon for valid regex.
    if (marks[0].length === 0 || marks[1].length === 0) {
        throw new Error("No marks provided.");
    }
    const escapeMe = /[-/\\^$*+?.()|[\]{}]/g;
    const start = marks[0].replace(escapeMe, "\\$&");
    const stop = marks[1].replace(escapeMe, "\\$&");
    const rex = new RegExp(start + "|" + stop, "g");
    const splitStrings = str.split(rex);

    const parsedStrings = splitStrings.map((s, i) => {
        if (i % 2 === 0) {
            return <SensitiveText key={i}>{s}</SensitiveText>;
        }
        else {
            return (
                <mark key={i} className="c-search-result__highlight">
                    <SensitiveText>{s}</SensitiveText>
                </mark>
            );
        }
    });

    return parsedStrings;
}

function getNoResultsMessage(filter: SearchFilterOptions): string {
    switch (filter) {
        case SearchFilterOptions.Messages:
            return "No messages found.";
        case SearchFilterOptions.Bonds:
            return "No bonds found.";
        case SearchFilterOptions.Calls:
            return "No calls found.";
        case SearchFilterOptions.Attachments:
            return "No attachments found.";
        default:
            return "No search results.";
    }
}

function getNoResultsCard(filter: SearchFilterOptions): React.JSX.Element {
    return (
        <div className="c-search-result c-search-result--no-result">
            <div className="c-search-result__content">
                <div className="c-search-result__header ">
                    <h3 className="c-search-result__title u-truncate">
                        {getNoResultsMessage(filter)}
                    </h3>
                </div>
            </div>
        </div>
    );
}

export function SearchView(): React.JSX.Element {
    const marks = useSelector(selectHighlightMarks);
    const selectedFilter = useSelector(selectFilter);
    const results = useSelector(selectResults);
    const filteredResults = results?.filter(result => filterMatches(result, selectedFilter)) ?? [];
    const noResultsMsg = getNoResultsCard(selectedFilter);

    return (
        <FeatureFlagged className="c-content" flag={"search-feature-frontend"} match={true}>
            <SearchFilterAndPresenceHeader />
            {filteredResults && (
                <CardContainer>
                    {filteredResults.map((result, i) => (
                        <SearchResultMultiplex result={result} key={i} marks={marks} />
                    ))}
                </CardContainer>
            )}
            {filteredResults.length === 0 && <div>{noResultsMsg}</div>}
        </FeatureFlagged>
    );
}

function filterMatches(result: SearchResult, filter: SearchFilterOptions): boolean {
    if (filter === SearchFilterOptions.Everything) return true;
    if (isMessageSearchResult(result)) return filter === SearchFilterOptions.Messages;
    if (isBondSearchResult(result)) return filter === SearchFilterOptions.Bonds;
    if (isCallSearchResult(result)) return filter === SearchFilterOptions.Calls;
    if (isAttachmentSearchResult(result)) return filter === SearchFilterOptions.Attachments;
    return false;
}

function SearchResultMultiplex(
    { result, marks }: { result: SearchResult; marks: [string, string]; },
): React.JSX.Element {
    const filter = useAppSelector(selectFilter);

    if (!filterMatches(result, filter)) return <></>;
    if (isMessageSearchResult(result)) {
        return <MessageSearchResultCard result={result} marks={marks} />;
    }
    if (isBondSearchResult(result)) return <BondSearchResultCard result={result} marks={marks} />;
    if (isCallSearchResult(result)) return <CallSearchResultCard result={result} marks={marks} />;
    if (isAttachmentSearchResult(result)) {
        return <AttachmentSearchResultCard result={result} marks={marks} />;
    }
    return <></>;
}
interface SearchResultCardProps {
    bondId: Optional<d.BondId>;
    dateTime?: number;
    snippet?: string;
    marks?: [string, string];
    redirectUrl: string;
    type?: string;
}

function SearchResultCard(
    { bondId, dateTime, snippet, marks, redirectUrl, type }: SearchResultCardProps,
): React.JSX.Element {
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);
    const navigate = useNavigate();
    const resultClasses = "c-search-result" + (type ? ` c-search-result--${type}` : "");
    const iconClasses = type ? `c-search-result__icon c-search-result__icon--${type}` : "";

    return (
        <div
            className={resultClasses}
            onClick={() => navigate(redirectUrl)}
        >
            <div className={iconClasses} />
            <div className="c-search-result__content">
                <div className="c-search-result__header">
                    <h3 className="c-search-result__title u-truncate">{bondTitle.title}</h3>
                    {dateTime && (
                        <time
                            className="c-search-result__time"
                            dateTime={new Date(dateTime).toLocaleString()}
                        >
                            <TimeAgo from={dateTime} live />
                        </time>
                    )}
                </div>
                {snippet && marks &&
                    (
                        <p className="c-search-result__summary u-clamp_2">
                            {parseHTML(snippet, marks)}
                        </p>
                    )}
                <div className="c-search-result__meta">
                    <BondPrivacyDomain id={bondId} includeInvited={true} />
                </div>
            </div>
        </div>
    );
}

function MessageSearchResultCard(
    { result, marks }: { result: MessageSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.messageId);
    const bond = useSelectorArgs(selectBondById, bondId);

    if (!isOfficialChatMessage(message)) {
        return <div>Not a chat msg {content.messageId}.</div>;
    }

    if (!bond) <div>Message Search Error: no Bond with id:{bondId}.</div>;

    const defaultSnippet = message.content.message || "no snippet";

    const messageURL = `/bond/${d.extractUUID(bondId)}/message/${d.extractUUID(message.id)}`;
    return (
        <SearchResultCard
            bondId={bondId}
            snippet={content.highlight?.text?.snippet || defaultSnippet}
            dateTime={message.serverRxTs}
            redirectUrl={messageURL}
            marks={marks}
            type={"message"}
        />
    );
}

function BondSearchResultCard(
    { result, marks }: { result: BondSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const bond = useSelectorArgs(selectBondById, bondId);

    if (!bond) <div>Bond Search Error: no Bond with id:{bondId}.</div>;

    const bondURL = `/bond/${d.extractUUID(bondId)}`;

    const snippet = result.content.highlight.summary.snippet ||
        result.content.highlight.detailedSummary.snippet ||
        result.content.highlight.summary.snippet;
    const defaultSnippet = bond?.knowledge.summary || "no snippet";

    return (
        <SearchResultCard
            bondId={bondId}
            snippet={snippet || defaultSnippet}
            redirectUrl={bondURL}
            marks={marks}
            type={"bond"}
        />
    );
}

function CallSearchResultCard(
    { result, marks }: { result: CallSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.startMessageId);
    const bond = useSelectorArgs(selectBondById, bondId);
    const call = useSelectorArgs(selectCallById, content.callId);

    if (!isCallStartMessage(message)) {
        return <div>Not a call start message {content.startMessageId}.</div>;
    }

    if (!bond) <div>Call Search Error: no Bond with id:{bondId}.</div>;

    const messageURL = `/bond/${d.extractUUID(bondId)}/message/${d.extractUUID(message.id)}`;
    const snippet = content.highlight.title.snippet || content.highlight.shortSummary.snippet ||
        content.highlight.detailedSummary.snippet;
    const defaultSnippet = call?.knowledge.summary || "no snippet";

    return (
        <SearchResultCard
            bondId={bondId}
            snippet={snippet || defaultSnippet}
            dateTime={message.serverRxTs}
            redirectUrl={messageURL}
            marks={marks}
            type={"call"}
        />
    );
}

function AttachmentSearchResultCard(
    { result, marks }: { result: AttachmentSearchResult; marks: [string, string]; },
): React.JSX.Element {
    const content = result.content;
    const bondId = content.bondId;
    const message = useSelectorArgs(selectMessage, content.messageId);
    const bond = useSelectorArgs(selectBondById, bondId);

    if (!isOfficialChatMessage(message)) {
        return <div>Not an attachment message {content.messageId}.</div>;
    }

    if (!bond) <div>Attachment Search Error: no Bond with id:{bondId}.</div>;

    const messageURL = `/bond/${d.extractUUID(bondId)}/message/${d.extractUUID(message.id)}`;
    const snippet = content.highlight.shortDescription.snippet ||
        content.highlight.longDescription.snippet ||
        content.highlight.filename.snippet;
    const defaultSnippet = "no snippet"; // todo fetch attachment knowledge

    return (
        <SearchResultCard
            bondId={bondId}
            snippet={snippet || defaultSnippet}
            dateTime={message.serverRxTs}
            redirectUrl={messageURL}
            marks={marks}
            type={"attachment"}
        />
    );
}
